import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AcceptApiInvite } from '../api/ApiTypes';
import { LoadingImage } from '../components/loading/LoadingComponent';
import Message from '../components/layouts/Message';
import { postApi } from '../api/api';

const LoggedInAcceptInvite = () => {
    const { inviteCode } = useParams() as any;
    const urlParams = new URLSearchParams(window.location.search);
    const spaceId = urlParams.get('spaceId');

    const [error, setError] = React.useState('');
    const [waiting, setWaiting] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const history = useHistory();

    useEffect(() => {
        postApi('/station/acceptInvite', {
            inviteCode
        })
            .then(async (response) => {
                setSuccess(response.ok);
                setWaiting(false);
                if (response.ok) {
                    const json = await response.json();
                    const { station } = json as AcceptApiInvite;
                    const isLite = document.location.pathname.endsWith('/lite');
                    const url = (spaceId ? `/space/${spaceId}/${isLite? 'lite': 'lobby'}${window.location.search}` : `/station/${station!.id}`);
                    history.push(url);
                } else {
                    setError((await response.json()).message);
                }
            })
            .catch((e) => {
                console.error(e);
                setWaiting(false);
                setSuccess(false);
            });
    }, [inviteCode, history, spaceId]);

    return (
        <Message title="Opening a portal ...">
            {waiting ? (
                <LoadingImage />
            ) : success ? (
                <>
                    You&apos;re in! <br />
                    <br />
                </>
            ) : error ? (
                error
            ) : (
                'something went wrong.'
            )}
        </Message>
    );
};

export default LoggedInAcceptInvite;
