import * as React from 'react';
import styled from 'styled-components/macro';
import StreamRoomForm from './StreamRoomForm';
import { ApiSpace, ApiStationDetails } from '../../api/ApiTypes';
import { StreamContext } from './StreamContext';

const StreamSend = () => {
    const { stationDetails } = React.useContext(StreamContext);
    const spaces = stationDetails?.spaces;
    const openSpaces = spaces?.filter((space) => space.isOpen);

    if (stationDetails === null || !spaces || !openSpaces) {
        console.error('[StreamSend] stationDetails is null');
        return null;
    } else {
        return (
            <Container>
                Stream Send <SpaceCounter>to Spaces <span className="noTranslate">{`${openSpaces.length}`}</span> of <span className="noTranslate">{spaces.length}</span></SpaceCounter>
                <SpaceList spaces={spaces} stationDetails={stationDetails} className='noTranslate'></SpaceList>
            </Container>
        );
    }

};

const SpaceList = ({spaces, stationDetails, className}: {spaces: ApiSpace[], stationDetails: ApiStationDetails, className?: string}) => {
    return (
        <ListContainer className={className?? ''}>
            <StreamRoomForm stationDetails={stationDetails}></StreamRoomForm>
        </ListContainer>
    );
};


const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 15px;
}`;

const SpaceCounter = styled.span`
    font-weight: normal;
    font-size: 16px;
`;

const ListContainer = styled.div`
    position: relative;
    width: 98%;
    height: 220px;
    margin-top: 23px;
    background-color: #030217;
    box-shadow: inset 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 3px;
`;

export default StreamSend;
