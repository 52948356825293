import * as React from 'react';
import styled from 'styled-components/macro';
import StreamSource from './StreamSource';
import StreamSend from './StreamSend';
import StreamDestination from './StreamDestination';
import { ApiStationDetails } from '../../api/ApiTypes';

const StreamIO = () => {
    return (
        <Container>
            <TopContainer>
            <StreamSource />
            <StreamSend />
            </TopContainer>
            <StreamDestination/>
        </Container>
    );
};

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    `

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 350px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #1b1a4d;
    font-size: 20px;
    font-weight: bold;
`;


export default StreamIO;
