import * as React from 'react';
import _ from 'lodash';
import { ApiUser, ApiStationRole, ApiSpaceRole, StationRoleType } from '../../api/ApiTypes';
import Table from './Table';
import ProfileNonagon from '../orbit/ProfileNonagon';
import styled from 'styled-components/macro';
import { SvgIconButton } from '../inputs/Button';
import { HostIcon, BlockedIcon, CheckIcon, DeleteIcon } from '../../images/icons';
import moment from 'moment';
import { StationDetailContext } from '../../context/stationContext';
import { SpaceDetailContext } from '../../context/spaceContext';
import { postApi, deleteApi, putApi} from '../../api/api';
import { LoadingImage } from '../loading/LoadingComponent';
import TimeUtils from '../../utils/TimeUtils';
import VerticalDotMenu from '../VerticalDotMenu';
import { getCurrentUser } from '../../store/selectors';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, ModalText, ModalHeader, ModalButtons } from '../layouts/Modal';
import { tri } from 'm.m';
import { getNameFromUser } from '../../utils/tableUtils';
import RemoveSelfFromStationModal from '../modals/RemoveSelfFromStationModal';
import { canAdmin } from '../../permissions/users';

type TableUser = ApiStationRole | ApiSpaceRole;

const PresenceColumn = {
    accessor: 'isOnline',
    Cell: function buildProfileImg({ value }: { value: boolean }) {
        return (
            <ProfileDisplay>
                {value && (
                    <DotContainer>
                        <Dot />
                    </DotContainer>
                )}
            </ProfileDisplay>
        );
    },
    width: 25,
    disableSortBy: true
};

const buildProfileImg = ({ value }: { value: string }) => {
  return (
    <ProfileDisplay>
        <ProfileNonagon avatarUrl={value} size={50} />
    </ProfileDisplay>
  );
}

export const buildAvatarColumn = (accessor = 'user.avatarUrl') => ({
    accessor,
    Cell: buildProfileImg,
    width: 80,
    disableSortBy: true
});

const DotContainer = styled.div`
    line-height: 2px;
`;

const Dot = styled.div`
    height: 15px;
    width: 15px;
    background-color: limegreen;
    border-radius: 50%;
    display: inline-block;
`;

const NameColumn = {
    Header: 'NAME',
    accessor: 'user.firstName',
    Cell: function buildName({ row: { original: tableUser } }: { row: { original: TableUser } }) {
        return <NameDisplay>{getNameFromUser(tableUser.user)}</NameDisplay>;
    },
    width: 200
};

const EmailColumn = {
    Header: 'EMAIL',
    accessor: 'user.email',
    width: 250
};

const VisitsColumn = {
    Header: '# OF VISITS',
    accessor: 'stats.timesEntered',
    width: 100,
    Cell: ({ value }: { value?: number }) => value || 0
};

const DurationColumn = {
    Header: 'TOTAL DURATION',
    accessor: 'stats.totalTimeSpentSecs',
    Cell: ({ value }: { value?: number }) => (value ? TimeUtils.formatDuration(value) : '0min')
};

const LastActiveColumn = {
    Header: 'LAST ACTIVE',
    accessor: 'stats.lastTimeSeen',
    Cell: ({ value }: { value?: string }) => (value ? formatApiDate(value) : '-')
};

const formatApiDate = (date: string) => moment(date).format('MMM D | h:mm A');

const FullUserTable = ({
    stripEmail,
    users,
    title,
    subtitle,
    buttonComponent: ButtonComponent,
    skipRef
}: {
    stripEmail?: boolean;
    users: TableUser[];
    title?: string;
    subtitle?: string;
    buttonComponent: React.ComponentType<TableButtonProps>;
    skipRef?: any;
}) => {
    const userData = React.useMemo(() => users?.filter((user) => !!user.user), [users]);

    let columns = React.useMemo(
        () => [
            PresenceColumn,
            buildAvatarColumn(),
            NameColumn,
            EmailColumn,
            VisitsColumn,
            DurationColumn,
            {
                Header: 'DATE ADDED',
                accessor: 'createdAt',
                Cell: ({ value }: { value: string }) => formatApiDate(value)
            },
            LastActiveColumn,

            {
                id: 'dots',
                Cell: function buildDots({ row: { original: tableUser } }: { row: { original: TableUser } }) {
                    return <ButtonComponent user={tableUser} isManager={false} />;
                },
                width: 30,
                disableSortBy: true
            }
        ],
        []
    );

    if (stripEmail) {
        const columnsToRemove: Array<typeof EmailColumn> = [columns[3]] as any;
        columns = _.difference(columns, columnsToRemove);
    }

    return (
        <Table
            columns={columns}
            data={userData}
            defaultSort={[{ id: 'user.firstName', desc: false }]}
            title={title}
            subtitle={subtitle}
            skipRef={skipRef}
        />
    );
};

export const SimpleUserTable = ({
    stripEmail,
    users,
    isManager,
    subtitle,
    title,
    buttonComponent: ButtonComponent,
    skipRef
}: {
    stripEmail?: boolean;
    users?: TableUser[];
    isManager?: boolean;
    subtitle: string;
    title: string;
    buttonComponent: React.ComponentType<TableButtonProps>;
    skipRef?: any;
}) => {
    const userData = React.useMemo(() => users?.filter((user) => !!user.user), [users]);

    let columns = React.useMemo(
        () => [
            buildAvatarColumn(),
            NameColumn,
            EmailColumn,
            VisitsColumn,
            DurationColumn,
            LastActiveColumn,
            {
                id: 'dots',
                Cell: function buildDots({ row: { original: tableUser } }: { row: { original: TableUser } }) {
                    return <ButtonComponent user={tableUser} isManager={isManager || false} />;
                },
                width: 30,
                disableSortBy: true
            }
        ],
        [isManager]
    );

    if (stripEmail) {
        const columnsToRemove: Array<typeof EmailColumn> = [columns[2]] as any;
        columns = _.difference(columns, columnsToRemove);
    }

    return (
        <Table
            columns={columns}
            data={userData || []}
            defaultSort={[{ id: 'user.firstName', desc: false }]}
            subtitle={subtitle}
            title={title}
            skipRef={skipRef}
        />
    );
};
export const ManagerTable = ({ users, skipRef, stripEmail }: { users: TableUser[], skipRef?: any, stripEmail?: boolean }) => {
    const filteredUsers = users.filter((user) => ['OWNER', 'MANAGER'].includes(user.role));

    return (
        <SimpleUserTable
            users={filteredUsers}
            subtitle="People with manager access to this station"
            title="Manager List"
            buttonComponent={StationUserTableButtons}
            isManager={true}
            skipRef={skipRef}
            stripEmail={stripEmail}
        />
    );
};

export const StationUserTable = ({ users, removeAllMembers, skipRef, stripEmail }: { users: TableUser[]; removeAllMembers: () => void , skipRef?: any, stripEmail?: boolean}) => {
    if (users === undefined) {
        return <LoadingImage />;
    }

    const filteredUsers = users.filter((user) => user.role === 'MEMBER');

    return (
        <StationUserTableWrapper>
            <RemoveAllUsersButton
                onClick={() => removeAllMembers()}
                activeColor="var(--burnt-red)"
                baseColor="var(--off-white)"
                iconType="stroke"
            >
                <DeleteIcon /> Remove All Members
            </RemoveAllUsersButton>
            <FullUserTable
                users={filteredUsers || []}
                title="Attendee List"
                subtitle="People with access to this station"
                buttonComponent={StationUserTableButtons}
                skipRef={skipRef}
                stripEmail={stripEmail}
            />
        </StationUserTableWrapper>
    );
};

const StationUserTableWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const RemoveAllUsersButton = styled(SvgIconButton)`
    font-size: 10px;
    position: absolute;
    top: 30px;
    right: 0px;
`;

export const StationBlockedUserTable = ({ users, skipRef, stripEmail }: { users: TableUser[], skipRef?: any, stripEmail?: boolean }) => {
    if (users === undefined) {
        return <LoadingImage />;
    }

    const filteredUsers = users.filter((user) => user.role === 'OUTCAST');

    return (
        <FullUserTable
            users={filteredUsers || []}
            title="Blocked Players"
            subtitle="People forbidden from this space"
            buttonComponent={StationUserTableButtons}
            skipRef={skipRef}
            stripEmail={stripEmail}
        />
    );
};

export const SpaceHostTable = ({ users, skipRef, stripEmail }: { users: TableUser[], skipRef?: any, stripEmail?: boolean }) => {
    const filteredUsers = users.filter((user) => user.role === 'HOST');

    return (
        <SimpleUserTable
            users={filteredUsers}
            subtitle="People with host access to this space"
            title="Host List"
            buttonComponent={SpaceUserTableButtons}
            isManager={true}
            skipRef={skipRef}
            stripEmail={true}
        />
    );
};

export const SpaceBlockedUserTable = ({ users, skipRef, stripEmail }: { users: TableUser[], skipRef?: any, stripEmail?: boolean }) => {
    const filteredUsers = users.filter((user) => user.role === 'OUTCAST');

    return (
        <FullUserTable
            users={filteredUsers}
            title="Block List"
            subtitle="People forbidden from this space"
            buttonComponent={SpaceUserTableButtons}
            skipRef={skipRef}
            stripEmail={stripEmail}
        />
    );
};

export const SpaceMemberTable = ({ users, skipRef, stripEmail }: { users?: TableUser[], skipRef?: any, stripEmail?: boolean }) => {
    if (users === undefined) {
        return <LoadingImage />;
    }

    const filteredUsers = users.filter((user) => {
        return user.role === 'MEMBER' && tri(() => Number(user!.stats!.timesEntered)).recover(() => 0).get > 0;
    });

    return (
        <FullUserTable
            stripEmail={true}
            users={filteredUsers}
            title="Attendee List"
            subtitle="People with access to this space"
            buttonComponent={SpaceUserTableButtons}
            skipRef={skipRef}
        />
    );
};

const NameDisplay = styled.span`
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: var(--midnight);
    padding-right: 20px;
`;

const ProfileDisplay = styled.div`
    padding: 0 15px;
`;

const Divider = styled.div`
    height: 30px;
    width: 1px;
    opacity: 0.9;
    border: 1px solid #fff;
    margin: 0 5px;
`;

type TableButtonProps = {
    user: TableUser;
    isManager: boolean;
};

const StationUserTableButtons = ({ user, isManager }: TableButtonProps) => {
    const [showUnmakeManagerWarning, setShowUnmakeManagerWarning] = React.useState(false);
    const me = useSelector(getCurrentUser);
    const email = me.email;

    return (
        <>
            {isManager && user.user.email === email && (
                <UnmakeManagerSelfModal
                    user={user}
                    hideModal={() => setShowUnmakeManagerWarning(false)}
                    isOpen={showUnmakeManagerWarning}
                />
            )}
            <MenuWrapper>
                <VerticalDotMenu>
                    {canAdmin(me) && !['OUTCAST', 'OWNER'].includes(user.role) && (
                      <MakeOwnerButton user={user} />
                    )}
                    {user.role !=='OWNER' && (isManager ? (
                        user.user.email === email ? (
                            <UnmakeManagerSelfButton openWarningModal={() => setShowUnmakeManagerWarning(true)} />
                        ) : (
                            <UnmakeManagerButton user={user} />
                        )
                    ) : (
                        <MakeManagerButton user={user} />
                    ))}
                    {user.role !== 'OWNER' && <Divider />}
                    {user.role !== 'OUTCAST' && (
                      user.user.email === email ? (
                        <RemoveSelfButton />
                      ) : (
                        <RemoveButton user={user} />
                      )
                    )}
                    {user.role !== 'OUTCAST' && !isManager && <BlockButton user={user} />}
                    {user.role === 'OUTCAST' && <UnblockButton user={user} />}
                </VerticalDotMenu>
            </MenuWrapper>
        </>
    );
};

const MakeOwnerButton = ({ user }: { user: TableUser }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <StationDetailContext.Consumer>
      {({ stationDetails, setStationDetails }) => {
        const setRole = (u: ApiStationRole) => {
          if (u.role === 'OWNER') return 'MANAGER';
          if (u.user.id === user.user.id) return 'OWNER'
          return u.role
        }

        const handleMakeOwner = () => {
            setLoading(true);
            putApi(`/station/${stationDetails.station.id}/owner`, { userId: user.user.id })
                .then(async (r) => {
                    setLoading(false);
                    const json = await r.json();
                    if (!r.ok) {
                        console.log(json.message || 'Error: make station manager failed');
                    } else {
                      setStationDetails({
                          ...stationDetails,
                          users: stationDetails.users
                            ? stationDetails.users.map((u: ApiStationRole) => ({
                                ...u,
                                role: setRole(u),
                              }))
                              : []
                      });
                    }
                })
                .catch((e) => console.log(e));
        };

        return (
            <SvgIconButton
                onClick={() => handleMakeOwner()}
                activeColor="var(--purple-blue)"
                iconType="stroke"
                baseColor="var(--off-white)"
                disabled={loading}
            >
              <HostIcon /> {loading ? 'Working...' : 'Make Owner'}
            </SvgIconButton>
        );
      }}
    </StationDetailContext.Consumer>
  );
}

const SpaceUserTableButtons = ({ user, isManager }: TableButtonProps) => {
    const { email } = useSelector(getCurrentUser);
    const managerRoles = ['OWNER', 'MANAGER'] as StationRoleType[];

    return (
        <SpaceDetailContext.Consumer>
            {({ spaceDetails }) => (
                <MenuWrapper>
                    <VerticalDotMenu>
                        {user.role !== 'OUTCAST' && (
                            <SpaceRemoveButton user={user} disabled={user.user.email === email} />
                        )}
                        {user.role !== 'OUTCAST' &&
                            isManager &&
                            managerRoles.includes(spaceDetails.space.stationRole!) && (
                                <UnmakeHostButton user={user} disabled={user.user.email === email} />
                            )}
                        {user.role !== 'OUTCAST' && !isManager && <SpaceBlockButton user={user} />}
                        {user.role !== 'OUTCAST' &&
                            !isManager &&
                            managerRoles.includes(spaceDetails.space.stationRole!) && <MakeHostButton user={user} />}
                        {user.role === 'OUTCAST' && <SpaceUnblockButton user={user} />}
                    </VerticalDotMenu>
                </MenuWrapper>
            )}
        </SpaceDetailContext.Consumer>
    );
};

const MenuWrapper = styled.div`
    position: relative;
    width: 30px;
    height: 60px;
`;

const SpaceBlockButton = ({ user }: { user: TableUser }) => (
    <SpaceDetailContext.Consumer>
        {({ spaceDetails, setSpaceDetails }) => {
            const handleBlockUser = () => {
                postApi(`/station/${spaceDetails.space.stationId}/block`, { userId: user.user.id })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: block failed');
                        }
                        setSpaceDetails({
                            ...spaceDetails,
                            users: spaceDetails.users
                                ? spaceDetails.users.map((u) =>
                                      u.user.id === user.user.id ? { ...user, role: 'OUTCAST' } : u
                                  )
                                : []
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleBlockUser()}
                    activeColor="var(--burnt-red)"
                    iconType="fill"
                    baseColor="var(--off-white)"
                >
                    <BlockedIcon /> Block
                </SvgIconButton>
            );
        }}
    </SpaceDetailContext.Consumer>
);

const BlockButton = ({ user }: { user: TableUser }) => (
    <StationDetailContext.Consumer>
        {({ stationDetails, setStationDetails }) => {
            const handleBlockUser = () => {
                postApi(`/station/${stationDetails.station.id}/block`, { userId: user.user.id })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: block failed');
                        }
                        setStationDetails({
                            ...stationDetails,
                            users: stationDetails.users
                                ? stationDetails.users.map((u) =>
                                      u.user.id === user.user.id ? { ...user, role: 'OUTCAST' } : u
                                  )
                                : []
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleBlockUser()}
                    activeColor="var(--burnt-red)"
                    iconType="fill"
                    baseColor="var(--off-white)"
                >
                    <BlockedIcon /> Block
                </SvgIconButton>
            );
        }}
    </StationDetailContext.Consumer>
);

const SpaceUnblockButton = ({ user }: { user: TableUser }) => (
    <SpaceDetailContext.Consumer>
        {({ spaceDetails, setSpaceDetails }) => {
            const handleUnblockUser = () => {
                postApi(`/station/${spaceDetails.space.stationId}/unblock`, { userId: user.user.id })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: unblock failed');
                        }
                        setSpaceDetails({
                            ...spaceDetails,
                            users: spaceDetails.users
                                ? spaceDetails.users.map((u) =>
                                      u.user.id === user.user.id ? { ...user, role: 'MEMBER' } : u
                                  )
                                : []
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleUnblockUser()}
                    activeColor="var(--soft-green)"
                    iconType="fill"
                    baseColor="var(--off-white)"
                >
                    <CheckIcon /> Unblock
                </SvgIconButton>
            );
        }}
    </SpaceDetailContext.Consumer>
);

const UnblockButton = ({ user }: { user: TableUser }) => (
    <StationDetailContext.Consumer>
        {({ stationDetails, setStationDetails }) => {
            const handleUnblockUser = () => {
                postApi(`/station/${stationDetails.station.id}/unblock`, { userId: user.user.id })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: unblock failed');
                        }
                        setStationDetails({
                            ...stationDetails,
                            users: stationDetails.users
                                ? stationDetails.users.map((u) =>
                                      u.user.id === user.user.id ? { ...user, role: 'MEMBER' } : u
                                  )
                                : []
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleUnblockUser()}
                    activeColor="var(--soft-green)"
                    iconType="fill"
                    baseColor="var(--off-white)"
                >
                    <CheckIcon /> Unblock
                </SvgIconButton>
            );
        }}
    </StationDetailContext.Consumer>
);

const RemoveButton = ({ user }: { user: TableUser }) => (
    <StationDetailContext.Consumer>
        {({ stationDetails, setStationDetails }) => {
            const handleRemoveUser = () => {
                deleteApi(`/station/${stationDetails.station.id}/users/${user.user.id}`)
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                          console.log(json.message || 'Error: remove failed');
                          alert(json.message);
                        } else {
                          setStationDetails({
                              ...stationDetails,
                              users: stationDetails.users
                                  ? stationDetails.users.filter((u) => u.user.id !== user.user.id)
                                  : []
                          });
                        }
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleRemoveUser()}
                    activeColor="var(--burnt-red)"
                    baseColor="var(--off-white)"
                    iconType="stroke"
                >
                    <DeleteIcon /> Remove
                </SvgIconButton>
            );
        }}
    </StationDetailContext.Consumer>
);

const RemoveSelfButton = () => {
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <StationDetailContext.Consumer>
        {({ stationDetails, setStationDetails }) => {
          return (
            <>
              <RemoveSelfFromStationModal
                isOpen={modalOpen}
                hideModal={() => setModalOpen(false)}
                stationId={stationDetails.station.id!}
              />
              <SvgIconButton
                  onClick={() => setModalOpen(true)}
                  activeColor="var(--burnt-red)"
                  baseColor="var(--off-white)"
                  iconType="stroke"
              >
                  <DeleteIcon /> Remove
              </SvgIconButton>
            </>
          );
        }}
    </StationDetailContext.Consumer>
  );
};

const SpaceRemoveButton = ({ user, disabled }: { user: TableUser; disabled: boolean }) => (
    <SpaceDetailContext.Consumer>
        {({ spaceDetails, setSpaceDetails }) => {
            const handleRemoveUser = () => {
                deleteApi(`/station/${spaceDetails.space.stationId}/users/${user.user.id}`)
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: remove failed');
                        }
                        setSpaceDetails({
                            ...spaceDetails,
                            users: spaceDetails.users
                                ? spaceDetails.users.filter((u) => u.user.id !== user.user.id)
                                : []
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleRemoveUser()}
                    activeColor="var(--burnt-red)"
                    baseColor="var(--off-white)"
                    iconType="stroke"
                    disabled={disabled}
                >
                    <DeleteIcon /> Remove
                </SvgIconButton>
            );
        }}
    </SpaceDetailContext.Consumer>
);

const MakeManagerButton = ({ user }: { user: TableUser }) => (
    <StationDetailContext.Consumer>
        {({ stationDetails, setStationDetails }) => {
            const handleMakeManager = () => {
                postApi(`/station/${stationDetails.station.id}/managers/add`, { userId: user.user.id })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: make station manager failed');
                        }
                        setStationDetails({
                            ...stationDetails,
                            users: stationDetails.users
                                ? stationDetails.users.map((u) =>
                                      u.user.id === user.user.id ? { ...user, role: 'MANAGER' } : u
                                  )
                                : []
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    disabled={['OUTCAST'].includes(user.role)}
                    onClick={() => handleMakeManager()}
                    activeColor="var(--purple-blue)"
                    iconType="stroke"
                    baseColor="var(--off-white)"
                >
                    <HostIcon /> Make Manager
                </SvgIconButton>
            );
        }}
    </StationDetailContext.Consumer>
);

const UnmakeManagerButton = ({ user }: { user: TableUser }) => (
    <StationDetailContext.Consumer>
        {({ stationDetails, setStationDetails }) => {
            const handleUnmakeManager = () => {
                postApi(`/station/${stationDetails.station.id}/managers/remove`, { userId: user.user.id })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: unmake station manager failed');
                        }
                        setStationDetails({
                            ...stationDetails,
                            users: stationDetails.users
                                ? stationDetails.users.map((u) =>
                                      u.user.id === user.user.id ? { ...user, role: 'MEMBER' } : u
                                  )
                                : []
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleUnmakeManager()}
                    activeColor="var(--burnt-red)"
                    iconType="stroke"
                    baseColor="var(--off-white)"
                >
                    <HostIcon /> Remove As Manager
                </SvgIconButton>
            );
        }}
    </StationDetailContext.Consumer>
);

const UnmakeManagerSelfModal = ({
    user,
    hideModal,
    isOpen
}: {
    user: TableUser;
    hideModal: () => void;
    isOpen: boolean;
}) => {
    const history = useHistory();
    const [error, setError] = React.useState('');

    return (
        <StationDetailContext.Consumer>
            {({ stationDetails }) => {
                const handleUnmakeManager = () => {
                    postApi(`/station/${stationDetails.station.id}/managers/remove`, { userId: user.user.id })
                        .then(async (r) => {
                            const json = await r.json();
                            if (!r.ok) {
                                setError(json.message || 'Error: unmake station manager failed');
                            } else {
                              history.push(`/station/${stationDetails.station.id}`);
                            }
                        })
                        .catch((e) => console.log(e));
                };

                return (
                    <Modal handleClose={hideModal} isOpen={isOpen}>
                        <ModalHeader>Are you sure you want to remove yourself as a station manager?</ModalHeader>
                        <ModalText>You will lose all manager privileges at this station.</ModalText>
                        <ModalButtons>
                            <CancelButton onClick={hideModal}>Cancel</CancelButton>
                            <SvgIconButton
                                onClick={() => handleUnmakeManager()}
                                activeColor="var(--burnt-red)"
                                iconType="stroke"
                                baseColor="var(--off-white)"
                                disabled={!!error}
                            >
                                <HostIcon /> Yes, Remove Me As Manager
                            </SvgIconButton>
                        </ModalButtons>
                        {!!error && <ModalError>{error}</ModalError>}
                    </Modal>
                );
            }}
        </StationDetailContext.Consumer>
    );
};

const MakeHostButton = ({ user }: { user: TableUser }) => (
    <SpaceDetailContext.Consumer>
        {({ spaceDetails, setSpaceDetails }) => {
            const handleMakeHost = () => {
                postApi(`/space/${spaceDetails.space.id}/hosts/add`, { userId: user.user.id })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: make space host failed');
                        }
                        setSpaceDetails({
                            ...spaceDetails,
                            users: spaceDetails.users
                                ? spaceDetails.users.map((u) =>
                                      u.user.id === user.user.id ? { ...user, role: 'HOST' } : u
                                  )
                                : []
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    disabled={['OUTCAST'].includes(user.role)}
                    onClick={() => handleMakeHost()}
                    activeColor="var(--purple-blue)"
                    iconType="stroke"
                    baseColor="var(--off-white)"
                >
                    <HostIcon /> Make Host
                </SvgIconButton>
            );
        }}
    </SpaceDetailContext.Consumer>
);

const UnmakeHostButton = ({ user, disabled }: { user: TableUser; disabled: boolean }) => (
    <SpaceDetailContext.Consumer>
        {({ spaceDetails, setSpaceDetails }) => {
            const handleUnmakeHost = () => {
                postApi(`/space/${spaceDetails.space.id}/hosts/remove`, { userId: user.user.id })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: unmake space host failed');
                        }
                        setSpaceDetails({
                            ...spaceDetails,
                            users: spaceDetails.users
                                ? spaceDetails.users.map((u) =>
                                      u.user.id === user.user.id ? { ...user, role: 'MEMBER' } : u
                                  )
                                : []
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleUnmakeHost()}
                    activeColor="var(--burnt-red)"
                    iconType="stroke"
                    baseColor="var(--off-white)"
                    disabled={disabled}
                >
                    <HostIcon /> Remove As Host
                </SvgIconButton>
            );
        }}
    </SpaceDetailContext.Consumer>
);


const ModalError = styled(ModalText)`
    color: var(--burnt-red);
`;

const CancelButton = styled.button`
    appearance: none;
    color: var(--burnt-red);
    font-size: 16px;
    font-weight: bold;
    background: none;
    border: none;
    margin-right: 20px;
`;

const UnmakeManagerSelfButton = ({ openWarningModal }: { openWarningModal: () => void }) => (
    <SvgIconButton
        onClick={() => openWarningModal()}
        activeColor="var(--burnt-red)"
        iconType="stroke"
        baseColor="var(--off-white)"
    >
        <HostIcon /> Remove As Manager
    </SvgIconButton>
);
