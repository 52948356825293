import * as React from 'react';
import styled from 'styled-components/macro';

type EmbedTitleProps = {
    title: string;
};

const EmbedTitle: React.FC<EmbedTitleProps> = ({ title, children }) => (
    <Wrapper>
        <Title>{title}</Title>
        <Description>{children}</Description>
    </Wrapper>
);

const Wrapper = styled.div`
    width: 160px;
    color: var(--off-white);
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 20px;
    padding-top: 16px;
`;

const Title = styled.h1`
    margin: 0 0 5px 0;
    height: 36px;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: normal;
`;

const Description = styled.h1`
    font-size: 14px;
    line-height: 1.43;
    font-weight: normal;
`;

export default EmbedTitle;
