import * as React from 'react';

import styled from 'styled-components/macro';

import loadingGif from '../../images/loading-40px_1loading-40px.gif';

const LoadingScreenContainer = styled.div`
    position: fixed;
    left: 0;
    top: 60px;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    width: 100vw;
    height: 100vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--navy-blue);
`;

export default function LoadingScreen() {
    return (
        <LoadingScreenContainer>
            <img src={loadingGif} alt="" className="loading-img" />
        </LoadingScreenContainer>
    );
}
