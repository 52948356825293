import * as React from 'react';
import styled from 'styled-components/macro';
import useCopyClipboard from '../../hooks/useCopyClipboard';
import { GreyishBodyText } from '../Typography';
import { useEffect, useState } from 'react';
import { getApi, putApi } from '../../api/api';
import { ApiStationDetails, StationDistributionInviteRules, ApiSpace } from '../../api/ApiTypes';
import { TinyButton } from '../inputs/Button';
import { Select } from '../inputs/Select';
import ValidatedFormInput from '../inputs/ValidatedFormInput';
import { useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '../../images/atom-i-info-alt.svg';
import Divider from '../Divider';

interface Props {
    station: ApiStationDetails;
    header: string;
    icon: string;
    tooltip: string;
    children?: any;
}


const StationDistributionInviteForm: React.FC<Props> = ({ station, header, icon, tooltip, children }) => {
  const mergeSpaces = (isSelectAll = false) => {
    const _disSp = station.station?.distributionInviteRules?.spaces?? ([] as StationDistributionInviteRules['spaces']);
    const _ssp = station.spaces;

    const fullSpaceList =  _ssp.reduce((acc: StationDistributionInviteRules['spaces'], s: ApiSpace) => {
      if(acc.find((_s) => _s.spaceId === s.id)) return acc;
      return acc.concat({ spaceId: s.id, isSelected: isSelectAll });
    }, _disSp);

    if(!fullSpaceList.find((s) => s.isSelected)) return fullSpaceList.map((s) => {
      return { ...s, isSelected: !!(station.spaces.find((_s) => s.spaceId === _s.id)?.isActive) }
    });

    return fullSpaceList;
  };

  const getDistribution = () => {
    return {
      strategy: (station.station?.distributionInviteRules?.strategy?? 'ROUND_ROBIN'),
      groupSize: (station.station?.distributionInviteRules?.groupSize?? 10),
      spaces: mergeSpaces(!station.station?.distributionInviteRules),
      allSpacesFullOverflow: (station.station?.distributionInviteRules?.allSpacesFullOverflow?? '_spectate:__SEND_USER_TO_SPECTATE__'),
    };
  }

  const [link, setLink] = useState<string>('');
  const [LinkCopied, setLinkCopied] = useCopyClipboard(link, { successDuration: 2000 });
  const [distribution, setDistribution] = useState<StationDistributionInviteRules>(getDistribution());
  const [showSequence, setShowSequence] = useState(false);

  const strategies = [
      { label: 'Distribute Evenly',              value: 'ROUND_ROBIN' },
      { label: 'Fill Spaces In Order',           value: 'FILL_SEQUENTIALLY' },
      { label: 'Fill Space #1, Then Distribute', value: 'FILL_FIRST' },
      { label: 'Random Assignment',              value: 'RANDOM' },
      { label: 'Disabled',                       value: 'DISABLED' },
  ];

  const overflows = [
      {
          label: 'Station Overview',
          value: `_station:${station.station!.id!}`
      }, {
          label: 'Spectate Mode in Space 1',
          value: '_spectate:__SEND_USER_TO_SPECTATE__'
      }
  ];

  const getInviteLink = async () => {
    return getApi(`/meet/station/${station.station!.id!}/distribution/code`).then(async (res) => {
      const result = await res.json();
      console.log('INVITE: ', result);
      const code = result.code;
      return `${window.location.protocol}//${window.location.host}/meetInvite/${code}`;
    }).catch(() => '');
  };

  const getSpaceTitle = (index: number) => {
    const spaceId = distribution.spaces[index].spaceId;
    return station.spaces.find((s) => s.id === spaceId)?.title?? 'Unkown'
  }

  const getSpaceCapacity = (index: number) => {
    const spaceId = distribution.spaces[index].spaceId;
    return station.spaces.find((s) => s.id === spaceId)?.capacity?? 0;
  }

  const toggleIsSelected = (index: number) => {
    const spaces = distribution.spaces;
    const spaceId = spaces[index].spaceId;

    const _spaces = spaces.map((s) => {
      return (s.spaceId !== spaceId)? s: { ...s, isSelected: !s.isSelected }
    });

    //Prevent all spaces from being unselected
    if(_spaces.filter((s) => s.isSelected).length < 1) return;
    //Otherwise we can set the distribution
    setDistribution({ ...distribution, spaces: _spaces });
  }

  const setGroupSize = (groupSize: number) => {
    if(groupSize > 0) setDistribution({ ...distribution, groupSize });
  }

   const getSpaceActiveStatus = (i: number) => {
    try {
      const spaceId = distribution.spaces[i].spaceId;
      return station.spaces.find((s) => s?.id === spaceId)?.isActive
    } catch(e) {
      return false;
    }
  };

  const getTotalCap = () => {
    return station.spaces.filter(
      (s) => distribution.spaces.find((_s) => _s.spaceId === s.id)?.isSelected && s.isActive
    ).reduce(
      (acc, s) => acc + s.capacity, 0
    );
  }

  const moveSpace = (currentIndex: number, direction: 'UP' | 'DOWN') => {

    const arr = distribution.spaces;

    if ((direction === 'UP' && currentIndex > 0) || (direction === 'DOWN' && currentIndex < arr.length - 1)) {
        const itemToMove = arr[currentIndex];
        arr.splice(currentIndex, 1);
        const newIndex = direction === 'UP' ? currentIndex - 1 : currentIndex + 1;
        arr.splice(newIndex, 0, itemToMove);
    }

    setDistribution({ ...distribution, spaces: arr });

  };


  useEffect(() => {
    getInviteLink().then((il) => setLink(il));
  }, [station]);

  useEffect(() => {
      putApi(`/station/${station.station!.id!}`, { distributionInviteRules: distribution }).then(async (res) => {
        const result = await res.json();
        console.log('Staiton Update Result', result);
      }).catch((e: Error) => console.log('Could not update station distributionInviteRules with error: ', e));
  }, [distribution]);


  const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });


  return (
    <Container>
      <div>
          <TopSection>
              <img src={icon} alt="link icon" style={{ marginRight: '15px' }} />
              <h3>{header}</h3>
          </TopSection>
          <GreyishBodyText style={{ marginBottom: '30px' }}>{tooltip}</GreyishBodyText>
      </div>
      <div>{children}</div>
      <LinkContainer>
          <LinkText className='noTranslate'>{link}</LinkText>
          <TinyButton onClick={setLinkCopied}>{LinkCopied ? 'Copied!' : 'copy'}</TinyButton>
      </LinkContainer>
      <Divider style={{ margin: '5px 0' }} />
      <LinkContainer style={{ marginTop: '5px' }}>
        <DistStratWrap>
          <Select
            onChange={(value) => setDistribution({ ...distribution, strategy: value })}
            value={distribution.strategy}
            options={strategies}
            spClassName="noTranslate"
          />
        </DistStratWrap>
        <ValidatedFormInput
            labelText=""
            className="w-input"
            errors={errors}
            id="groupSize"
            name="groupSize"
            onChange={(value: any) => { setGroupSize(parseInt(value.target.value)) }}
            validation={register({})}
            type="number"
            value={distribution.groupSize}
            style={{ width: '78px', marginTop: '10px', marginLeft: '20px', textAlign: 'left' }}
        />
        <CustomLabel htmlFor="groupSize">Min Per Space</CustomLabel>
        <ReactTooltip effect="solid" />
        <InactiveInfo
            data-tip="Will fill each space sequentialy up to the set minimum, then distribute as specified by your choosen strategy"
            src={InfoIcon}
            style={{ marginTop: '-18px', textAlign: 'left'}}
        />
        <Spacer/>
      </LinkContainer>

      <LinkContainer style={{ marginTop: '5px' }}>
        <PullUp>
          <Select
            onChange={(value) => setDistribution({ ...distribution, allSpacesFullOverflow: value })}
            value={distribution.allSpacesFullOverflow}
            options={overflows}
            spClassName="noTranslate"
          />
        </PullUp>
        <CustomLabelBottom>Overflow guests, if at capacity</CustomLabelBottom>
        <ReactTooltip effect="solid" />
        <InactiveInfo
            data-tip="Choose the overflow strategy to apply when all spaces are full"
            src={InfoIcon}
            style={{ marginTop: '-32px', marginLeft: '-125px' }}
        />
        <SpacerII/>
      </LinkContainer>

      <ManageLabel onClick={() => setShowSequence(!showSequence)}>{!showSequence && <span>&gt;</span>}{showSequence && <span>⋁</span>} Manage Sequence</ManageLabel>
      {showSequence && <TotalsInfo>Total Configuration Capacity: <TotalCap>{getTotalCap()} Guests</TotalCap></TotalsInfo>}
      {showSequence && <SpaceSelector>
        {[...Array(distribution.spaces.length).keys()].map((i) => (
          <LI key={distribution.spaces[i].spaceId} style={{ backgroundColor: ((distribution.spaces[i].isSelected && getSpaceActiveStatus(i))? 'rgb(21, 18, 60)': 'rgb(24, 24, 36)'), color: ((distribution.spaces[i].isSelected && getSpaceActiveStatus(i))? 'rgb(255, 255, 255)': 'rgb(155, 155, 155)') }}>
            <IW>{i + 1}. {getSpaceTitle(i)}{!getSpaceActiveStatus(i) && <span title="Space is closed!" style={{ cursor: 'pointer' }}> ⚠️</span>}</IW>
            <IW>
              <Cap>{getSpaceCapacity(i)} <NB>CAP.</NB></Cap>
              <span>
                { !distribution.spaces[i].isSelected && <BWrapOut onClick={() => toggleIsSelected(i)}>✔</BWrapOut> }
                { distribution.spaces[i].isSelected && <BWrap onClick={() => toggleIsSelected(i)}>✔</BWrap> }
                { distribution.spaces[i].isSelected && (i > 0) && <BWrap onClick={() => moveSpace(i, 'UP')}>⋀</BWrap> }
                { (!distribution.spaces[i].isSelected || (i < 1)) && <BWrapDisabled>⋀</BWrapDisabled> }
                { (distribution.spaces[i].isSelected && ((i + 1) < distribution.spaces.length)) && <BWrap onClick={() => moveSpace(i, 'DOWN')}>⋁</BWrap> }
                { (!distribution.spaces[i].isSelected || ((i + 1) >= distribution.spaces.length)) && <BWrapDisabled>⋁</BWrapDisabled> }
              </span>
            </IW>
          </LI>
        ))}
      </SpaceSelector>}
      <Divider style={{ margin: '5px 0' }} />
    </Container>
  );
};



const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 45px;
    margin-bottom: 55px;
`;

const TopSection = styled.div`
    display: flex;
    margin-bottom: 15px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 900px;
`;

const LinkText = styled.div`
  font-size: 16px;
  letter-spacing: 0.33px;
`;

const SpaceSelector = styled.ol`
  width: 100%;
  margin-left:0px;padding-left:0px
`;

const LI = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: rgb(21, 18, 60);
  margin-bottom: 5px;
  padding: 5px;
`;

const IW = styled.span`
  padding-left: 10px;
  padding-right: 10px;
`;

const Cap = styled.span`
  font-weight: bold;
  margin-right: 25px;
`;

const NB = styled.span`
  font-weight: normal;
`;

const BWrap = styled.span`
  background-color: white;
  border-radius: 3px;
  padding-left: 3px;
  padding-right: 3px;
  color: black;
  font-weight: bold;
  margin-right: 3px;
  border: 1px solid #FFF;
  cursor: pointer;
`;

const BWrapOut = styled(BWrap)`
  background-color: rgb(19, 18, 27);
  border: 1px solid #FFF;
  font-color: rgb(19, 18, 27);
`;

const BWrapCheck = styled(BWrap)`
  font-color: rbg(46, 27, 216);
  border: 1px solid rbg(46, 27, 216);
`;

const BWrapDisabled = styled(BWrap)`
  opacity: 0.33;
`;

const CustomLabel = styled.label`
  margin-left: 15px;
  margin-top: 20px;
  text-align: left;
  width: 150px;
`;

const Spacer = styled.span`
  width: 100px;
`

const SpacerII = styled.span`
  width: 10px;
`

const CustomLabelBottom = styled.label`
  text-align: left;
  width: 400px;
  margin-left: 0px;
`;

const ManageLabel = styled.label`

`

const InactiveInfo = styled.img`
  margin-left: 10px;
  margin-top: -15px;
  fill: #ffffff;
`;

const PullUp = styled.span`
  margin-top: -20px;
  width: 312px;
`;

const TotalsInfo = styled.span`
  color: rgb(155, 155, 155);
  text-transform: uppercase;
  margin-bottom: 15px;
`;

const TotalCap = styled.span`
  color: rgb(255, 255, 255);
  font-weight: bold;
`;

const DistStratWrap = styled.span`
  width: 385px;
`;


export default StationDistributionInviteForm;
