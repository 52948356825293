import * as React from 'react';
import { useEffect } from 'react';
import useInterval from '../hooks/useInterval';
import styled from 'styled-components/macro';
import EmbedTitle from '../components/embeds/EmbedTitle';
import { useParams, useHistory } from 'react-router-dom';
import { getApi } from '../api/api';
import { ApiStationDetails, ApiSpace } from '../api/ApiTypes';
import LoadingScreen from '../components/loading/LoadingScreen';
import { SpaceCardLabel } from '../components/station/SpaceCardLabel';
import { OutlineButtonStyles, SmallButtonStyles, OutlineButton, SmallButton } from '../components/inputs/Button';
import { ExitIcon } from '../images/icons';
import { Modal } from '../components/layouts/Modal';

const sortSpaces = (a: ApiSpace, b: ApiSpace) =>
    a.attendance! > b.attendance! ? 1 : a.attendance === b.attendance ? (a.title > b.title ? 1 : -1) : -1;

const PortalEmbed = () => {
    const { spaceId } = useParams() as { spaceId: string };
    const [stationDetails, setStationDetails] = React.useState(({} as unknown) as ApiStationDetails);
    const [stationDetailsLoading, setStationDetailsLoading] = React.useState(true);
    const [modalLink, setModalLink] = React.useState('');
    const [modalDestination, setModalDestination] = React.useState('');
    const [modalOpen, setModalOpen] = React.useState(false);
    const history = useHistory();

    const fromSpaces = window.location.search.includes('fromSpaces');

    const returnToSpaces = () => {
      const idToken =  new URL(location.href).searchParams.get('idToken');
      const query = (idToken? `?idToken=${idToken}`: '');
      history.push(`/embeds/spaces/${spaceId}${query}`);
    }

    const getSpaceInfo = () => {
        getApi(`/space/${spaceId}/station`).then(async (stationDetailsResponse) => {
            if (stationDetailsResponse.ok) {
                setStationDetails(await stationDetailsResponse.json());
            }
            setStationDetailsLoading(false);
        });
    };

    useEffect(() => {
        getSpaceInfo();
    }, [spaceId]);

    useInterval(() => {
        getSpaceInfo();
    }, 5000);

    if (stationDetailsLoading) return <LoadingScreen />;

    const spaces = stationDetails?.spaces;
    const thisSpace = spaces.filter((space) => space.id === spaceId)[0];
    const openSpaces = spaces.filter((space) => space.id !== spaceId && space.isActive).sort(sortSpaces);

    const openModal = (link: string, text: string) => {
        setModalLink(link);
        setModalDestination(text);
        setModalOpen(true);
    };

    return (
        <>
            <PortalEmbedWrapper>
                <EmbedTitle title="Portals">Quickly navigate between other spaces at this station</EmbedTitle>
                {fromSpaces && (<BackToSpacesButton onClick={() => returnToSpaces()}>ALL SPACES</BackToSpacesButton>)}
                <CurrentSpace space={thisSpace} stationTitle={stationDetails.station.title} handleClick={openModal} />
                <SpaceLinkList>
                    {openSpaces.map((space) => (
                        <SpaceLink
                            key={space.id}
                            onClick={() => {
                                openModal(`/space/${space.id}/lobby?skipWelcome=1`, space.title);
                            }}
                        >
                            <SpaceCardLabel
                                space={space}
                                isEditing={false}
                                isActive={true}
                                topRightLine={space.environment?.displayName || ''}
                                middleRightLine={space.title}
                                bottomRightLine={space.subtitle}
                                topLeftLine={'Active'}
                                middleLeftLine={space.isActive ? space?.attendance || '0' : '- -'}
                                bottomLeftLine={`of ${space.capacity}`}
                            />
                        </SpaceLink>
                    ))}
                </SpaceLinkList>
            </PortalEmbedWrapper>

            <Modal isOpen={modalOpen}>
                <ModalWrapper>
                    <ModalTitle>Are you sure you want to leave this space and go to {modalDestination}</ModalTitle>
                    <ModalButtons>
                        <OutlineButton onClick={() => setModalOpen(false)}>Cancel</OutlineButton>
                        <GreenButtonLink target="_top" href={modalLink}>
                            Yep, let&apos;s go!
                        </GreenButtonLink>
                    </ModalButtons>
                </ModalWrapper>
            </Modal>
        </>
    );
};

const ModalWrapper = styled.div`
    display: flex;
    max-width: 600px;
    flex-direction: column;
`;

const ModalTitle = styled.h1`
    margin: 0 0 55px;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.33;
`;

const ModalButtons = styled.div`
    display: flex;
    width: 100%;
`;

const PortalEmbedWrapper = styled.div`
    display: flex;
    height: 255px;
    padding-top: 20px;
`;

const BackToSpacesButton = styled(SmallButton)`
    position: absolute;
    top: 232px;
    left: 20px;
`;

type CurrentSpaceProps = {
    space: ApiSpace;
    stationTitle: string;
    handleClick: (link: string, text: string) => void;
};

const CurrentSpace = ({ space, stationTitle, handleClick }: CurrentSpaceProps) => (
    <CurrentWrapper>
        <div>
            <CurrentText>You&apos;re currently in...</CurrentText>
            <CurrentStationTitle>{stationTitle}</CurrentStationTitle>
        </div>

        <SpaceCardLabel
            space={space}
            isEditing={false}
            isActive={true}
            topRightLine={space.environment?.displayName || ''}
            middleRightLine={space.title}
            bottomRightLine={space.subtitle}
            topLeftLine={'Active'}
            middleLeftLine={space.isActive ? space?.attendance || '0' : '- -'}
            bottomLeftLine={`of ${space.capacity}`}
            useHover={false}
        />

        <CurrentText>Click a space tile on the right to jump &gt;</CurrentText>

        <CurrentExitButton onClick={() => handleClick(`/station/${space.stationId}`, ' the station page')}>
            <ExitIcon />
            <span>EXIT TO OVERVIEW</span>
        </CurrentExitButton>
    </CurrentWrapper>
);

const CurrentExitButton = styled.button`
    ${OutlineButtonStyles}
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.71px;
    text-transform: uppercase;
    flex: 0 0;
    padding: 7px 16px;
    line-height: 1;
    fill: none;
    width: 220px;

    .svgIcon {
        margin-top: -2px;

        &:hover {
            fill: none;
        }
    }

    path {
        fill: none;
        transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
        stroke: var(--off-white);
    }

    &:hover {
        path {
            fill: none;
            stroke: var(--darkest-purple);
        }
    }
`;

const CurrentWrapper = styled.div`
    height: 255px;
    padding: 12px 30px 15px 30px;
    border-radius: 5px;
    margin-left: 170px;
    background-color: var(--dark);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--off-white);
`;

const CurrentText = styled.p`
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    margin: 0;
`;

const CurrentStationTitle = styled.h2`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
`;

const SpaceLinkList = styled.div`
    height: 255px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
`;

const SpaceLink = styled.button`
    text-decoration: none;
    border: none;
    ouline: none;
    appearance: none;
    margin-left: 15px;
    background: transparent;
    padding: 0;

    &:hover {
        text-decoration: none;
    }
`;

const GreenButtonLink = styled.a`
    ${SmallButtonStyles}
    text-decoration: none;
    margin-left: 15px;
    background: var(--tennis-green);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    font-weight: bold;
    text-transform: uppercase;
    border: 2px solid var(--tennis-green);
    color: var(--midnight) !important;

    &:hover {
        text-decoration: none;
        background-color: transparent;
        color: white !important;
        border-color: white;
    }
`;

export default PortalEmbed;
