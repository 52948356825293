import * as React from 'react';
import { useEffect } from 'react';
import {useState} from 'react';
import styled from 'styled-components/macro';

const LabeledToggle = ({leftLabel, rightLabel, value, changeHandler, externalControl}: {leftLabel: string, rightLabel: string, value: boolean, changeHandler: (v: boolean) => void, externalControl?: boolean}) => {
    // True = Left is selected
    // externalControl = true means props determine the UI state of the toggle.
    const [uiValue, uiValueSetter] = useState(value);

    const onToggleClick = (v: boolean) => {
        if (!externalControl) uiValueSetter(v);
        changeHandler(v);
    }

    return (
        <ToggleContainer>
            <Left value={externalControl ? value : uiValue} onClick={() => onToggleClick(true)}>{leftLabel}</Left>
            <Right value={externalControl ? value : uiValue} onClick={() => onToggleClick(false)}>{rightLabel}</Right>
        </ToggleContainer>
    )
}

export default LabeledToggle;

const ToggleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: fit-content;
    height: 30px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
    color: var(--midnight);
    background-color: var(--acanthus-grey);
`;

const commonStyles = `
    width: fit-content;
    height: 30px;
    border-radius: 20px;
    padding: 5px 20px 0px 20px;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
`;

const Left = styled.div<{value: boolean}>`
    ${commonStyles}
    ${(props) => props.value ?
        `background-color: var(--tennis-green)` : ``
    };
`;

const Right = styled.div<{value: boolean}>`
    ${commonStyles}
    ${(props) => props.value ?
        `` : `background-color: var(--tennis-green)`
    };
`;

const Oval = styled.div<{ $on: boolean }>`
    width: 22px;
    height: 22px;
    background-color: white;
    border-radius: 20px;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    box-shadow: 1px 1px 6px 0 var(--shadow-black);
    margin: ${({ $on }) => (!$on ? '4px 0 0 7px' : '4px 0 0 31px')};
`;
