import * as React from 'react';
import styled from 'styled-components/macro';

import { useContext } from 'react';
import { StreamContext } from './StreamContext';

const StreamDestination = () => {
    const streamContext = useContext(StreamContext);

    const onStreamDestinationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const select = event.currentTarget;
        const streamDestination = select.value;
        streamContext.updateStreamSpaces(streamContext.stream.id, streamContext.stream.spaces, streamDestination);
    }

    return (
        <StyledDestinationContainer>
            <StyledTitle>Stream Destination</StyledTitle>
            <StyledSelect
                className="stream-destination-select"
                defaultValue={'Screen'}
                onChange={onStreamDestinationChange}
            >
                <StyledOption value={'Screen'}>Screen</StyledOption>
                <StyledOption value={'Skybox'}>Skybox</StyledOption>
                <StyledOption value={'Screen+Skybox'}>Screen + Skybox</StyledOption>
            </StyledSelect>
        </StyledDestinationContainer>
    );
};

const StyledDestinationContainer = styled.div`
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 15px 15px 15px;
`;

const StyledTitle = styled.div`
    font-weight: bold;
    font-size: 20px;
`;

const StyledSelect = styled.select`
    width: 50%;
    height: 34px;
    padding-left: 10px;
    margin-top: 15px;
    border-radius: 5px;
    font-weight: normal;
    font-size: 15px;
    color: var(--midnight);
`;

const StyledOption = styled.option`

`;

export default StreamDestination;
