import * as React from 'react';
import { useState} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import AllSpaces from '../components/spaces/AllSpaces';
import { LiveSpaces, FeaturedSpaces, FollowedStations } from '../components/spaces/LiveFeaturedSpaces';
import MyStations from '../components/spaces/MyStations';
import History from '../components/spaces/History';
import { OutlineButton, SmallButton, SmallButtonStyles } from '../components/inputs/Button';
import { Modal } from '../components/layouts/Modal';
import { envAsBool } from '../utils/envLoader';

import { ReactComponent as GlobalIcon } from '../images/i-global.svg';
import { ReactComponent as HistoryIcon } from '../images/i-history.svg';
import { ReactComponent as HostIcon } from '../images/i-host.svg';


const SpacesEmbed = () => {
    const { spaceId } = useParams() as { spaceId: string };
    const [selectedTab, selectedTabSetter] = useState('discover');
    const [ displayModal, displayModalSetter ] = useState(false);
    const [ modalLink, modalLinkSetter ] = useState('');
    const [ modalDestination, modalDestinationSetter ] = useState('');

    const history = useHistory();

    const goToPortalsEmbed = () => {
      const idToken =  new URL(location.href).searchParams.get('idToken');
      const query = '?fromSpaces=1' + (idToken? `&idToken=${idToken}`: '');
      history.push(`/embeds/portals/${spaceId}${query}`);
    }

    const handleTabClick = (tab: string) => {
        if (tab === selectedTab) return;
        selectedTabSetter(tab);
    };

    const openModal = (link: string, text: string) => {
        modalLinkSetter(link);
        modalDestinationSetter(text);
        displayModalSetter(true);
    };

    return (
        <SpacesContainer>
            <Header>
                <SmallButton onClick={() => goToPortalsEmbed()}>VIEW CURRENT SPACE</SmallButton>
                {/* <SearchContainer>Search</SearchContainer> */}
            </Header>
            <Body>
                <TabSelectorContainer>
                    <TabSelector>
                        <Tab
                            selected={selectedTab === 'discover'}
                            onClick={() => handleTabClick('discover')}
                        >
                            <StyledGlobalIcon selected={selectedTab === 'discover'}/>
                        </Tab>
                        <Tab
                            selected={selectedTab === 'my-spaces'}
                            onClick={() => handleTabClick('my-spaces')}
                        >
                            <StyledHostIcon selected={selectedTab === 'my-spaces'}/>
                        </Tab>
                        <Tab
                            selected={selectedTab === 'history'}
                            onClick={() => handleTabClick('history')}
                        >
                            <StyledHistoryIcon selected={selectedTab === 'history'}/>
                        </Tab>
                    </TabSelector>
                </TabSelectorContainer>
                {BodyRenderer(selectedTab, openModal)}
            </Body>
            <Modal isOpen={displayModal}>
                <ModalWrapper>
                    <ModalTitle>Are you sure you want to leave this space and go to {modalDestination}</ModalTitle>
                    <ModalButtons>
                        <OutlineButton onClick={() => displayModalSetter(false)}>Cancel</OutlineButton>
                        <GreenButtonLink target="_parent" href={modalLink}>
                            Yep, let&apos;s go!
                        </GreenButtonLink>
                    </ModalButtons>
                </ModalWrapper>
            </Modal>
        </SpacesContainer>
    )
};

const BodyRenderer = (selectedTab: string, openModal: (link: string, text: string ) => void) => {
    switch (selectedTab) {
        case 'discover' :
            return (
                <DiscoverContainer>
                    {envAsBool('ENABLE_LIVE_NOW') && <LiveSpaces large={false} openModal={openModal}/>}
                    <FeaturedSpaces openModal={openModal}/>
                    <FollowedStations />
                    <AllSpaces openModal={openModal}/>
                </DiscoverContainer>
            );
        case 'my-spaces' :
            return (
                <MyStationsContainer>
                    <MyStations />
                </MyStationsContainer>
            );
        case 'history' :
            return (
                <HistoryContainer>
                    <History />
                </HistoryContainer>
            );
    }
}

const SpacesContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: var(--midnight);
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 7px 10px 15px 170px;

    @media (min-width: 375px) {
        padding: 7px 30px 15px 170px;
    }

    @media (min-width: 768px) {
        padding: 20px 90px 15px 90px;
    }
`;

const SearchContainer = styled.div`
    width: 240px;
    height: 40px;
    padding: 10px 16px 0 20px;
    border-radius: 23px;
    background-color: var(--off-white);
    color: grey;
`;

const Body = styled.div`
    width: 100%;
    height: 100%;
    flex: 1 0 auto;
    margin-bottom: 140px;
`;

const TabSelectorContainer = styled.div`
    position: fixed;
    top: 0;
    width: 150px;
    height: 45px;
    border-bottom-right-radius: 15px;
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.5);
    background-color: #1f2030;
    z-index: 1;

    @media (min-width: 768px) {
        position: fixed;
        top: 65px;
        width: 45px;
        height: 150px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.5);
    }
`;

const TabSelector = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;

    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
    }
`;

const Tab = styled.div<{selected: boolean}>`
    display: flex;
    justify-content: space-between;
    width: fit-content;
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    letter-spacing: 1.17px;
    font-weight: bold;
    text-transform: uppercase;

    ${(props) => props.selected ?
        `
            color: var(--tennis-green);
        `
        :
        `
            cursor: pointer;
        `
    }

    &:hover div {
        color: var(--tennis-green);
    }

    &:hover svg path {
        fill: var(--tennis-green);
    }
`;

const TabIconStyles = `
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: var(--main-transition);
`;

const StyledGlobalIcon = styled(GlobalIcon)<{selected: boolean}>`
    ${TabIconStyles}
    & path {
        fill: var(${(props) => props.selected ? '--tennis-green' : '--off-white'});
    }
`;

const StyledHostIcon = styled(HostIcon)<{selected: boolean}>`
    ${TabIconStyles}
    & path {
        fill: var(${(props) => props.selected ? '--tennis-green' : '--off-white'});
    }
`;

const StyledHistoryIcon = styled(HistoryIcon)<{selected: boolean}>`
    ${TabIconStyles}
    & path {
        fill: var(${(props) => props.selected ? '--tennis-green' : '--off-white'});
    }
`;

const DiscoverContainer = styled.div`
    padding 5px 10px 0 10px;

    @media (min-width: 375px) {
        padding: 5px 30px 0 30px;
    }

    @media (min-width: 768px) {
        padding: 5px 90px 0 90px;
    }
`;

const MyStationsContainer = styled.div`
    padding 5px 10px 0 10px;

    @media (min-width: 375px) {
        padding: 5px 30px 0 30px;
    }

    @media (min-width: 768px) {
        padding: 5px 90px 0 90px;
    }
`;

const HistoryContainer = styled.div`
    padding 5px 10px 0 10px;

    @media (min-width: 375px) {
        padding: 5px 30px 0 30px;
    }

    @media (min-width: 768px) {
        padding: 5px 90px 0 90px;
    }
`;

const ModalWrapper = styled.div`
    display: flex;
    max-width: 600px;
    flex-direction: column;
`;

const ModalTitle = styled.h1`
    margin: 0 0 55px;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.33;
`;

const ModalButtons = styled.div`
    display: flex;
    width: 100%;
`;

const GreenButtonLink = styled.a`
    ${SmallButtonStyles}
    text-decoration: none;
    margin-left: 15px;
    background: var(--tennis-green);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    font-weight: bold;
    text-transform: uppercase;
    border: 2px solid var(--tennis-green);
    color: var(--midnight) !important;

    &:hover {
        text-decoration: none;
        background-color: transparent;
        color: white !important;
        border-color: white;
    }
`;

export default SpacesEmbed;
