import * as React from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { envAsBool } from '../utils/envLoader';
import { writeSpaceControlState } from '../utils/CookieUtil';
import { auth } from '../firebase/Connection';
import { getApi, postApi } from '../api/api';
import { ApiSpace, ApiStation } from '../api/ApiTypes';
import { Helmet } from 'react-helmet';
import VimeoBackground from '../components/layouts/VimeoBackground';
import {useLocation} from "react-router-dom";
import UserAgentHelper from "../utils/UserAgentHelper";
import FrameUtil from "../utils/FrameUtil";
import { canSpacesBeOpened } from '../permissions/stations';

import {
    ErrorModal,
    NotAuthorizedModal,
    SpaceClosedModal,
    AtCapacityModal,
    ActiveConfirmModal,
    SpaceGateAuthModal,
    NoAnonAllowedModal,
    NoUnverifiedEmailModal,
    NewUserAccessDeniedModal,
    WelecomeWorldModal,
} from '../components/modals/LobbyModals';
import { verticalCentering } from '../css/positioning';
import { getCurrentUserId } from '../store/selectors';
import { getCurrentUser } from '../store/selectors';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import useShowPageHelp from '../hooks/useShowPageHelp';

interface TakeOverProps {
    isLoaded: boolean;
}

const TakeOver = styled.iframe<{ isLoaded: boolean }>`
    height: ${(props) => (props.isLoaded ? '100vh' : '100vh')};
    width: ${(props) => (props.isLoaded ? '100vw' : '100vw')};
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${(props) => (props.isLoaded ? 1 : 0)};
    z-index: ${(props) => (props.isLoaded ? 1000 : 1)};
    border: unset;
`;

const SpaceLobby = () => {
    const { spaceId, subSpace } = useParams() as any;

    const [loading, loadingSetter] = useState(false);
    const [needsActiveConfirm, setNeedsActiveConfirm] = useState(false);
    const [hostUrl, setHostUrl] = useState('');
    const [environmentName, setEnvironmentName] = useState('');
    const [gaveUp, setGaveUp] = useState(false);
    const [error, setError] = useState(false);
    const [healthy, setHealthy] = useState(false);
    const [is3DLoaded, setIs3DLoaded] = useState(!envAsBool('ENABLE_SPACE_LOADING_SCREEN'));
    const [idToken, setIdToken] = useState('');
    const [showVimeoCommponent, setShowVimeoCommponent] = useState(envAsBool('ENABLE_SPACE_LOADING_SCREEN'));
    const [notAuthorizedReason, setNotAuthorizedReason] = useState('' as string);
    const [notAuthorizedMessage, setNotAuthorizedMessage] = useState('' as string);
    const [notAuthorizedAddtionalParams, setNotAuthorizedAddtionalParams] = useState({} as  { [key: string]: any });
    const [uiVersion, setUiVersion] = useState('v1')
    const [space, setSpace] = useState<ApiSpace | undefined>(undefined);
    const [station, setStation] = useState<ApiStation | undefined>(undefined);
    const userId = useSelector(getCurrentUserId);
    const user = useSelector(getCurrentUser);
    const urlParamIsFirstTimeUser = (user.isFirstTimeUser === undefined || user.isFirstTimeUser)? "&isFirstTimeUser=true": "";
    const search = useLocation().search;
    const isForcedSpectate = String(new URLSearchParams(search).get('isForcedSpectate') || 'false').toLowerCase() === 'true';
    const userLang = new URLSearchParams(search).get('lang');
    const belowSpinnerLink = <a onClick={() => window.location.reload()}></a>
    const loadingScreenText = {
        head: 'Headphones On. Spatial Audio Ahead.',
        body: 'Close all other tabs, applications and go full screen for the ultimate NOWHERE experience.',
        belowSpinnerText: `If you don't auto continue after 10 seconds,`,
        belowSpinnerLink: belowSpinnerLink
    };
    // Initialize our pageVisit cookie for firsttime users
    const showHelp = useShowPageHelp();
    // This is false is we don't pass a page to the above hook
    if (showHelp) console.log('[SpaceLobby] show help');

    // true if the backend needs to be spun up and we know it'll take ~1 min
    const [created, setCreated] = useState(false);
    const [recentlyCreated] = useState(false);


    const shouldIFrameSpace = (host: string) => {
        if(!envAsBool('REMOVE_IFRAME_WHEN_POSSIBLE')) return true;
        return (new URL(host).host.split(':')[0].endsWith(window.location.host.split(':')[0]))? false: true;
    };

    const isFramed = FrameUtil.isFramed();

    useEffect(() => {
      setUiVersion(new URLSearchParams(search).get('ui') || (space?.uiVersion?? uiVersion));
    }, [space]);

    useEffect(() => {
        const loadPage = async () => {

            if (!spaceId) {
                console.warn('[SpaceLobby]loadPage spaceId is missing or invalid: ', spaceId);
            }

            console.log('[SpaceLobby] loading...');

            const idToken = await auth.currentUser?.getIdToken(/* forceRefresh */ true);
            if (!idToken) {
                setGaveUp(true);
                return;
            }
            setIdToken(idToken);

            const getDefaultsForErrorState = () => {
                return {
                    allowed: false,
                    message: "Could not determine acccess permissions",
                    reason: 'ERROR_RETRIEVING_PERMISSIONS',
                    space: {id: spaceId} as ApiSpace,
                    addtionalParams: {},
                    isAdmin: false,
                    station: {},
                    user: {},
                }
            }

            const getSpaceAccessInfo = async () => {
                let defaults = getDefaultsForErrorState();
                let retries = 5;

                const allowedInSpaceRoute = `/space/${spaceId}/allowed` + (isForcedSpectate? '/spectate': '');

                while (defaults?.reason === 'ERROR_RETRIEVING_PERMISSIONS') {
                  try {
                    defaults =  await (async () => {
                        return getApi(allowedInSpaceRoute).then((res) => res.json()).catch(() => getDefaultsForErrorState());
                    })();
                    if(defaults?.allowed === true) return defaults;
                  } catch (e) {
                    console.warn("[SpaceLobby] Error getting access info...", e);
                  }
                  retries -= 1; if(retries < 1) break;
                }
                return defaults;
            }

            const { allowed, message, reason, space, addtionalParams, isAdmin, station, user } =  await getSpaceAccessInfo();

            const _space = await (async () => {
              if(!space) {
                try {
                  return (await getApi(`/space/${spaceId}`).then((res) => res.json())).space;
                } catch(e) {
                  console.log('Could not get space with error: ', e);
                  return space;
                }
              }
              return space;
            })();

            setSpace(_space);
            setStation(station as ApiStation);

            if (!allowed) {
                // If the space is full but the person has access privileges, we still
                // want to show the space name as part of the error modal, so we need to
                // fetch the basic space data
                console.log(`[SpaceLobby]: SpaceAccess is not allowed: ${reason}`);
                if (['AT_CAPACITY', 'CRYPTO_ASSET_REQUIRED', 'NOT_ACTIVE', 'ERROR_RETRIEVING_PERMISSIONS', 'WELCOME_WORLD_REQUIRED'].indexOf(reason) > -1) {
                    try {
                        const spaceData = await (await getApi(`/space/${spaceId}`)).json();
                        setSpace(spaceData.space);
                    } catch (e) {
                        console.error("[SpaceLobby] SpaceAccess: Could not get space data with error: ", e);
                    }
                }
                setNotAuthorizedReason(reason!);
                setNotAuthorizedMessage(message!);
                setNotAuthorizedAddtionalParams(addtionalParams?? {});
                return;
            }

            // we know that this person is an admin of the space b/c otherwise
            // they wouldn't be allowed when it's closed
            if (!space?.isActive) {
                setNeedsActiveConfirm(true);
            }

            // first call the space info api
            const response = await postApi(`/space/${spaceId}/getOrCreateServer`, { isForcedSpectate }).catch(() => setError(true));
            if (!response || !response.ok) {
                setError(true);
                return;
            }

            const { hostUrl, created, environmentName, mobileEnvironmentName } = await response.json();

            setError(false);
            setHostUrl(hostUrl);
            setCreated(created);
            setEnvironmentName(UserAgentHelper.isMobile() ? mobileEnvironmentName: environmentName);

            setHealthy(true);
        };
        loadPage();
    }, [spaceId]);

    useEffect(() => {
        console.log('[SpaceLobby]hostUrl: ', hostUrl);
    }, [hostUrl]);

    const goTo3d = (meta3DUrl: string) => {
        console.log('Enjoy Nowhere, love maxwell');
        window.location.href = meta3DUrl;
    }

    if (error) return <ErrorModal />;
    if (notAuthorizedReason || notAuthorizedMessage) {
        if (notAuthorizedReason === 'ERROR_RETRIEVING_PERMISSIONS') {
            const attempt = parseInt(new URLSearchParams(search).get('attempt')?? '0');
            if(attempt < 2) {
                document.location.href = document.location.href.replace('attempt=', 'permerr=') + ((document.location.search)? '&': '?') + 'attempt=' + (attempt + 1);
                return <h3>Getting permissions...</h3>
            } else {
                return <NotAuthorizedModal space={space} notAuthorizedMessage={notAuthorizedMessage} />
            }
        } else if (notAuthorizedReason === 'AT_CAPACITY') {
            return <AtCapacityModal space={space} />;
        } else if (notAuthorizedReason === 'NOT_ACTIVE') {
            return <SpaceClosedModal space={space} />;
        } else if (notAuthorizedReason === 'CRYPTO_ASSET_REQUIRED') {
            return <SpaceGateAuthModal space={space} notAuthorizedMessage={notAuthorizedMessage} />;
        } else if (notAuthorizedReason === 'WHITELIST_INCLUSION_REQUIRED') {
            return <SpaceGateAuthModal space={space} notAuthorizedMessage={notAuthorizedMessage} />;
        } else if (notAuthorizedReason === 'NO_ANON_ALLOWED') {
            return <NoAnonAllowedModal space={space} notAuthorizedMessage={notAuthorizedMessage} />;
        } else if (notAuthorizedReason === 'NO_UNVERIFIED_EMAIL_ALLOWED') {
            return <NoUnverifiedEmailModal space={space} notAuthorizedMessage={notAuthorizedMessage} />;
        } else if (notAuthorizedReason === 'NEW_USER_ACCESS_IS_PAUSED') {
            return <NewUserAccessDeniedModal space={space} notAuthorizedMessage={notAuthorizedMessage} />;
        } else if (notAuthorizedReason === 'WELCOME_WORLD_REQUIRED') {
            return <WelecomeWorldModal space={space} notAuthorizedMessage={notAuthorizedMessage} addtionalParams={notAuthorizedAddtionalParams} />;
        } else {
            return <NotAuthorizedModal space={space} notAuthorizedMessage={notAuthorizedMessage} />;
        }
    }
    if (needsActiveConfirm) {
        if(canSpacesBeOpened(station as ApiStation)) {
          return <ActiveConfirmModal spaceId={spaceId} enterSpace={() => setNeedsActiveConfirm(false)} />;
        }
        return <SpaceClosedModal space={space} />;
    }

    if (healthy && idToken && !needsActiveConfirm) {
        const meta3DUrl = `${hostUrl}?space=${spaceId}`;

        let spaceParams = '';

        if (subSpace) {
            spaceParams += subSpace;
        }

        spaceParams += `scene=${environmentName}&userId=${userId}&space=${spaceId}&idToken=${idToken}&referrer=${encodeURIComponent(document.location.origin)}${urlParamIsFirstTimeUser}&ui=${uiVersion}`;

        if(isForcedSpectate) {
          spaceParams += '&isForcedSpectate=true';
        }

        if(space?.isWelcomeWorld) {
            spaceParams += '&isWelcomeWorld=true';
            const targetSpaceId = new URLSearchParams(search).get('targetSpaceId');
            if(targetSpaceId) spaceParams += `&targetSpaceId=${targetSpaceId}`;
        }

        const iframeLoaded = (e: any) => {
            console.log('[SpaceLobby] Loading 3D-Space', e);
            setTimeout(() => {
                console.log('[SpaceLobby] 3D-Space Loaded...');
                setShowVimeoCommponent(false);
                setIs3DLoaded(true);
                setIs3DLoaded(false); //Doing this to force a redraw
                setIs3DLoaded(true);
            }, 6000);
        };

        const params = new URLSearchParams(window.location.search);
        const isSkipWelcome = (() => {
          if(params.get('skipWelcome')) return true;
          if(isFramed && space?.embedRules?.isSkipWelcome) return true;

          return false;
        })();

        if(isSkipWelcome) spaceParams += '&skipWelcome=1';

        if(userLang) spaceParams += `&lang=${userLang}`;

        //If we are not framed and embed rules require frame we must go
        if (!isFramed && space?.embedRules?.isEmbedOnly) {
            const redirect = space?.embedRules?.redirectURL? space!.embedRules!.redirectURL!: `/station/${space.stationId}`;
            document.location.href = redirect;

            return null;
        } else if (!isFramed) { //if we are not framed and isEmbedOnly is false then we can write the cookie and go to 3D.
            writeSpaceControlState(spaceId, spaceParams);
            if (!shouldIFrameSpace(hostUrl)) goTo3d(meta3DUrl);

            return null;
        } else if (isFramed) { //If we are framed then we must check the reqired host param and set up redirect if needed.
            const rh = space?.embedRules?.requiredHost? space!.embedRules!.requiredHost!: '*';

            if (rh === '*' || rh === FrameUtil.parentHost()) {
                document.location.href = meta3DUrl + '&' + spaceParams;

                return null;
            } else {
                setTimeout(() => {
                    if (document.getElementById('embRdir')) document!.getElementById('embRdir')!.click();
                }, 2000);
            }
        }

        return (
            <>
                <Helmet>
                    <title>{`NOWHERE | ${space?.title || ''} | Lobby`}</title>
                </Helmet>

                {!userId && <Redirect to={{ pathname: '/log-in', search: '?hasUserId=false' }} />}

                {isFramed && userId && (
                  <a id="embRdir" target="_top" href={space?.embedRules?.redirectURL? space!.embedRules!.redirectURL!: '/station/' + (space?.stationId?? '')}>Click if you do not redirect in 3 seconds...</a>
                )}

                {!isFramed && userId && (
                    <TakeOver
                        isLoaded={is3DLoaded}
                        allow="display-capture *;camera *;microphone *;fullscreen *;gyroscope *;"
                        src={meta3DUrl + '&' + spaceParams}
                        onLoad={iframeLoaded}
                        id="TakeOverFrame"
                    />
                )}

                {showVimeoCommponent && (
                    <div id="VimeoVideoDiv">
                        <VimeoBackground vimeoId="438728376" />
                        <VideoOverlay>
                            <MessageDiv>
                                <h3>{gaveUp ? 'Space Failed to Load. Try again later.' : loadingScreenText.head}</h3>

                                {created && <h3>{loadingScreenText.body}</h3>}
                                {recentlyCreated && <h3>{loadingScreenText.body}</h3>}
                                <h3>{loadingScreenText.belowSpinnerText}{loadingScreenText.belowSpinnerLink}</h3>
                            </MessageDiv>
                        </VideoOverlay>
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>{`NOWHERE | ${space?.title || ''} | Lobby`}</title>
            </Helmet>
            {showVimeoCommponent && (
                <VideoOverlay>
                    <MessageDiv>
                        <h3>{gaveUp ? 'Space Failed to Load. Try again later.' : loadingScreenText.head}</h3>

                        {created && <h3>{loadingScreenText.body}</h3>}
                        {recentlyCreated && <h3>{loadingScreenText.body}</h3>}
                        <h3>{loadingScreenText.belowSpinnerText}{loadingScreenText.belowSpinnerLink}</h3>
                    </MessageDiv>
                </VideoOverlay>
            )}
        </>
    );
};

const MessageDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const VideoOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${verticalCentering},

    h3 {
        text-shadow: 3px 3px 18px rgba(0, 0, 0, 0.9);
    }
`;

export default SpaceLobby;
