import * as React from 'react';

import { useState } from 'react';
import { useEffect } from 'react';
import { ApiSpace } from '../../api/ApiTypes';
import { getApi, putApi } from '../../api/api';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { Select } from '../inputs/Select';
import LabeledToggle from '../inputs/LabeledToggle';
import ValidatedFormInput from '../inputs/ValidatedFormInput';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '../../images/atom-i-info-alt.svg';
import Divider from '../Divider';
import { WhiteLinkText } from '../Typography';
import { AuthButton } from '../inputs/Button';
import Datetime from 'react-datetime';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/selectors';
import { canManageRacingSubsection } from '../../permissions/users';

import "react-datetime/css/react-datetime.css";

import { v4 as uuid } from 'uuid';


type Point = [number, number, number];
type Cuboid = [Point, Point, Point, Point, Point, Point, Point, Point];

export const RaceConfigForm = ({ space, onSubmitSuccess }: { space: ApiSpace, onSubmitSuccess: () => void } )=> {
  const raceConfigKey = 'raceConfig';
  const user = useSelector(getCurrentUser);

  const defaultRaceConfig = {
    title: 'Let\'s Race!',
    description: 'Let\'s race in Nowhere!',
    instructions: '',
    logo: 'https://storage.googleapis.com/bin-nowhere/letsRace.webp',
    isAutoRace: true,
    maxRacers: 8,
    fixedSpeed: -1,
    numberOfLaps: 1, //How many laps
    lapAcceleration: 0, //% to increase / Decrease speed every lap in a fixedSpeed race
    isDoubleJumpEnabled: false,
    isBlastOffEnabled: false,
    isLocationSharingEnabled: false,
    modes: [{
      id: uuid(),
      name: 'Free Race',
      description: "It's free to join this race",
      isGameItemRequired: false,
      isBurnedAfterRacing: false,
      gameItemId: '',
      type: 'STANDARD',
    }],
    tournaments: {
      isEnabled: false,
      entryCircle: {
        location: [100, 0, 100],
        radius: 30,
        color: '#00FF00',
        texture: '',
      },
      provider: 'upland',
      currency: 'UPX',
      modes: [{
        id: uuid(),
        name: 'Low Stakes Tournament',
        description: 'Payed Tournament With Prizes!',
        fee: 100,
        timeout: 5, //If Tournament does not reach min racers in x minutes it will timeout
        minRacers: 3,
        prizeDistributionRules: [60, 30, 10],
        nowhereFeePercentage: 10,
        stationOwnerFeePercentage: 10,
      }],
    },
    startInterval: 10, //In minutes. Will close the circle and players will request their starting position
    startingGun: {
      timeUntilStart: 15, //Number of seconds from race start until starting gun
      sound: '', //Sound to play when race starts
      animation: { //Might need some other things here
        waitingAnimation: '', //Animation to play while waiting
        startAnimation: '', //Animation to play when race starts
      },
    },
    maxDuration: 120, //Max race duration in seconds
    minDuration: 60, //Min time a race maybe completed in (stop overt cheating)
    entryCircle: {
      location: [0, 0 , 0],
      radius: 30,
      color: '#FF0000',
      texture: '',
    },
    laneAssignmentDuration: 7, //Seconds
    startLine: {
      cuboid: [[ 0, 0, 0 ], [10, 0, 0], [10, 20, 0], [0, 20, 0], [0, 0, 10], [10, 0, 10], [10, 20, 10], [0, 20, 10]],
      texture: '',
      lanes: [],//[[[100, 0, 0], [0, 0, 0]], ]
    },
    finishLine: {
      cuboid: [[ 0, 0, 0 ], [10, 0, 0], [10, 20, 0], [0, 20, 0], [0, 0, 10], [10, 0, 10], [10, 20, 10], [0, 20, 10]],
      texture: '',
    },
    scorersTable: {
      teleportPoint: { location: [0,0,0], rotation: [0,0,0] },
    },
    podium: {
      startDelay: 5, //How long in seconds to delay before advancing to podium step
      winnerLocations: [],
      durration: 30, //How long to do the Podium celebration
      playerAnimations: ['firstPlaceDance', 'secondPlaceDance', 'thirdPlaceDance'],
      music: '', //URL to audio track?
    },
    scoreboard: {
      cycle: [
        { id: `D-${uuid()}`, type: 'DAILY',    name: 'Daily',          isActive: true,  duration: 10, isStationIncluded: false, isTournamentOnly: false },
        { id: `W-${uuid()}`, type: 'WEEKLY',   name: 'Weekly',         isActive: true,  duration: 10, isStationIncluded: false, isTournamentOnly: false },
        { id: `M-${uuid()}`, type: 'MONTHLY',  name: 'MONTHLY',        isActive: true,  duration: 10, isStationIncluded: false, isTournamentOnly: false },
        { id: `Y-${uuid()}`, type: 'YEARLY',   name: 'YEARLY',         isActive: false, duration: 10, isStationIncluded: false, isTournamentOnly: false },
        { id: `CUSTOM-${uuid()}`, type: 'CUSTOM',   name: 'Example Season', isActive: false, duration: 10, isStationIncluded: false, isTournamentOnly: false, startDateTime: 1718034769436, endDateTime: 1738034769436 },
      ]
    },
    raceCompleteTeleportPoint: { location: [0,0,0], rotation: [0,0,0] }, //Where to return players after podium
    raceResultsWebhook: '',
    autoCalibration: {
      entryCircle: true,
      tournamentEntryCircle: true,
      startingLine: true,
      finishLine: true,
      scorersTable: true,
      podium: true,
      raceComplete: true,
    },
  };

  const recurringPrizeDefaults = {
    id: uuid(),
    isActive: false,
    name: '',
    rules: {
      payoutAsPercentageOfProfits: 70,
      numberOfPrizes: 3,
      prizeDistributionRules: [60, 30, 10],
      altMinPayout: 100, //use 0 for no min payout
      altMaxPayout: 100000, //use -1 for no max payout
    },
    currency: 'UPX', //This (and provider) will be overriden if tournament from tournament settigns
    provider: 'upland',
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [raceConfig, setRaceConfig] = useState<{ [key: string]: any }>(defaultRaceConfig);
  const [isRaceable, setIsRaceable] = useState<boolean>(false);
  const [showEntryCircleLoc, setShowEntryCircleLoc] = useState<boolean>(false);
  const [showTournamentEntryCircleLoc, setShowTournamentEntryCircleLoc] = useState<boolean>(false);
  const [showStartLineVertex, setShowStartLineVertex] = useState<boolean[]>([false, false, false, false, false, false, false, false]);
  const [showStartLanes, setShowStartLanes] = useState<{ location: boolean; rotation: boolean; }[]>([]);
  const [showFinishLineVertex, setShowFinishLineVertex] = useState<boolean[]>([false, false, false, false, false, false, false, false]);
  const [showScorersTableTeleportPoint, setShowScorersTableTeleportPoint] = useState<boolean>(false);
  const [showPodiumWinnerLocations, setShowPodiumWinnerLocations] = useState<boolean[]>([false, false, false]);
  const [showRaceCompleteTeleportPoint, setShowRaceCompleteTeleportPoint] = useState<boolean>(false);
  const [showTMPrizeRules, setShowTMPrizeRules] = useState<boolean[]>([false]);
  const [showPrizePrizeRules, setShowPrizePrizeRules] = useState<boolean[]>([false]);
  const [editScoreboardCycleItem, setEditScoreboardCycleItem] = useState<{ idx: number; isEditting: boolean }>({ idx: -1, isEditting: false });
  const [newScoreboardCycleItem, setNewScoreboardCycleItem] = useState<{ id: string; type: string; name: string; isActive: boolean; startDateTime: number; endDateTime: number; }>({
    id: uuid(),
    type: 'CUSTOM',
    name: 'Custom Board',
    isActive: false,
    startDateTime: Date.now(),
    endDateTime: (Date.now() + 1),
  });
  const [showNewScoreboardCycleItemForm, setShowNewScoreboardCycleItemForm] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState(false);
  const [headingToggles, setHeadingToggles] = useState<{ [key: string]: boolean }>({
    metadata:             false,
    autoCalibration:      false,
    automaticRaceConfig:  false,
    racerRestrictions:    false,
    raceDynamics:         false,
    raceModes:            false,
    tournaments:          false,
    entryCircle:          false,
    laneAssignment:       false,
    startingGun:          false,
    startingLine:         false,
    finishLine:           false,
    scorersTableLocation: false,
    podium:               false,
    scoreboard:           false,
    raceComplete:         false,
  });

  const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });

  const toggleHeading = (heading: string) => {
    console.log('toggleHeading: ', heading);
    const isAccordion = true;
    let allHToggles   = headingToggles;
    const headings = Object.keys(allHToggles);

    const headingFinalValue = !allHToggles[heading];

    if(isAccordion) {
      allHToggles = headings.reduce((acc, item) => {
        acc[item] = false;
        return acc;
      }, allHToggles)
    }

    allHToggles[heading] = headingFinalValue;

    console.log(allHToggles);

    setHeadingToggles({ ...headingToggles, ...allHToggles });
  }

  const getPrizeConfigForCycle = (i: number) => {
    return raceConfig?.scoreboard?.cycle[i]?.prizes?? recurringPrizeDefaults;
  }

  const tournamentProviders = [
      { label: 'Upland', value: 'upland' },
      { label: 'Test', value: 'test' },
  ];

  const tournamentCurrencies = [
    { label: 'UPX', value: 'UPX' }
  ];

  const updateModes = (index: number, mode?: { [key: string]: any }) => {
    const modes = raceConfig.modes;
    const defaultMode = {
      id: uuid(),
      name: '',
      description: "",
      isGameItemRequired: false,
      isBurnedAfterRacing: false,
      gameItemId: '',
      type: 'STANDARD',
    };

    const _mode = mode?? defaultMode;

    if(index >= raceConfig.modes.length) {
      return { ...raceConfig, modes: modes.concat(_mode) };
    }

    if(!mode) {
      index = (index < 0)? modes.length -1: index;
      const newModes = [...modes.slice(0, index), ...modes.slice(index + 1)];
      return { ...raceConfig, modes: newModes };
    }

    modes[index] = _mode;
    return { ...raceConfig, modes };
  };

  const getRaceConfig = async () => {
    const result = await getApi(`/space/${space!.id!}/state/${raceConfigKey}`).then(async (resp) => {
      const isBadRequest = (resp.status > 399);
      const res = await resp.json();
      if(isBadRequest) throw new Error(res.msg);
      return res;
    })
    .catch((e: Error) => {
      console.log('Could not get race config with error: ', e);
      return { value: defaultRaceConfig };
    });

    console.log({ raceConfig: { ...raceConfig, ...result.value } });
    setRaceConfig({ ...raceConfig, ...result.value });
  };

  const handleRaceConfigUpdate = async () => {
    setSubmitting(true);
    const spaceId = space!.id!;
    //First update the space and set isRaceable
    const isSpacePutSuccess = await putApi(`/space/${spaceId}`, { isRaceable }).then(async (resp) => {
      const res = await resp.json();
      console.log({ spacePutResult: res });
      return true;
    }).catch((e: Error) => {
      console.log('Could not put space with error: ', e);
      return false;
    });

    if(!isSpacePutSuccess) {
      alert('Could not update space isRaceable...');
      setSubmitting(false);
      return;
    }

   const isSpaceStatePutSuccess = await putApi(`/space/${spaceId}/state/${raceConfigKey}?stateOnly=false&isValuePersistedAsString=true`, raceConfig).then(async (resp) => {
     const res = await resp.json();
     console.log({ spaceStatePutResult: res });
     return true;
   }).catch((e: Error) => {
     console.log('Could not put space with error: ', e);
     return false;
   });

   if(!isSpaceStatePutSuccess) {
     alert('Could not update Space State Race Config...');
     setSubmitting(false);
     return;
   }

   setSubmitting(false);

   onSubmitSuccess()
  };

  const setEntryFeeCycle = (idx: number, value?: number, isRemove=false) => {

    let _efc = [ ...(raceConfig.tournaments.entryFeeCycle?? [0]) ];
    const _val = ((value?? _efc.slice(-1))?? 0);

    _efc[idx] = _val;

    if(isRemove) {
      _efc = [..._efc.slice(0, idx), ..._efc.slice(idx + 1)];
    }

    setRaceConfig({ ...raceConfig, tournaments: { ...raceConfig.tournaments, entryFeeCycle: _efc } });
  }

  const moveRaceCycleItem = (currentIndex: number, direction: 'UP' | 'DOWN') => {
    setEditScoreboardCycleItem({ ...editScoreboardCycleItem, isEditting: false });

    const arr = raceConfig?.scoreboard?.cycle?? [];


    if ((direction === 'UP' && currentIndex > 0) || (direction === 'DOWN' && currentIndex < arr.length - 1)) {
        const itemToMove = arr[currentIndex];
        arr.splice(currentIndex, 1);
        const newIndex = direction === 'UP' ? currentIndex - 1 : currentIndex + 1;
        arr.splice(newIndex, 0, itemToMove);
    }

    setRaceConfig({ ...raceConfig, scoreboard: { ...raceConfig.scoreboard, cycle: arr } });
  };

  const addCustomScorebaordCycleItem = () => {
    const cycle = raceConfig?.scoreboard?.cycle?? [];
    const idx = cycle.length;
    const item = { ...newScoreboardCycleItem, id: uuid(), type: 'CUSTOM' };
    setRaceConfig({ ...raceConfig, scoreboard: { ...raceConfig.scoreboard, cycle: cycle.concat(item) } });
    setEditScoreboardCycleItem({ idx, isEditting: true });
  }

  const removeCustomScorebaordCycleItem = (idx: number) => {
    const cycle = raceConfig?.scoreboard?.cycle?? [];
    if(cycle[idx]?.type !== 'CUSTOM') return;
    cycle.splice(idx, 1);
    setRaceConfig({ ...raceConfig, scoreboard: { ...raceConfig.scoreboard, cycle: cycle } });
  };

  const editCustomScorebaordCycleItem = (idx: number, body: { [key: string]: any }) => {
    const cycle = raceConfig?.scoreboard?.cycle?? [];
    //if(cycle[idx]?.type !== 'CUSTOM') return;
    cycle[idx] = { ...cycle[idx], ...body };

    if(cycle[idx]?.isActive && cycle[idx]?.prizes?.isActive) {
     cycle[idx] = payoutPercentageHelper(idx, cycle[idx].prizes.rules.payoutAsPercentageOfProfits);
    }

    setRaceConfig({ ...raceConfig, scoreboard: { ...raceConfig.scoreboard, cycle: cycle } });
  };

  const payoutPercentageHelper = (i: number, percent?: number): { [key: string]: any } => {
    const curValue = raceConfig?.scoreboard?.cycle[i]?.prizes?.rules?.payoutAsPercentageOfProfits?? 0;
    const newValue = percent?? curValue;
    const allSc = (raceConfig?.scoreboard?.cycle?? []).filter((sc: { [key: string]: any }) => sc.isActive && sc?.prizes?.isActive);
    const total = allSc.reduce((acc: number, item: { [key: string]: any }) => acc + (item?.prizes?.rules?.payoutAsPercentageOfProfits?? 0), 0);

    const newTotal = (total - curValue) + newValue;

    console.log(i, raceConfig?.scoreboard?.cycle[i], 'New Total: ', newTotal, curValue, newValue);


    if(newTotal > 66.33) return payoutPercentageHelper(i, newValue - 1);


    const scoreboard = raceConfig!.scoreboard!;

    scoreboard.cycle[i].prizes.rules.payoutAsPercentageOfProfits = ((percent?? 0) < 0)? 0: percent!;

    return scoreboard.cycle[i];
  }

  const toggleCustomScorebaordCycleItemActivation = (idx: number) => {
    const cycle = raceConfig?.scoreboard?.cycle?? [];
    if(!cycle[idx]) return;
    cycle[idx] = { ...cycle[idx], isActive: !cycle[idx].isActive };
    setRaceConfig({ ...raceConfig, scoreboard: { ...raceConfig.scoreboard, cycle: cycle } });
  }


  const setModePrizeDistributionRules = (modeIdx: number, idx: number, value: number, minRacers?: number, distribution?: number[]): number[] => {
    const modes = getTournamentModes();
    minRacers = minRacers?? modes[modeIdx].minRacers;
    distribution = distribution?? modes[modeIdx].prizeDistributionRules;

    if((minRacers?? 0) < 1) {
      return [] as number[];
   }
    /* ^get out if things are off^ */

    const rules = distribution!.slice(0, minRacers!);

    value = ( (idx + 1) > minRacers! )? 0: ( (value < 0)? 0: value );

    rules[idx] = value;

    const total = rules.reduce((acc: number, item: number) => acc + item, 0);
    if(total !== 100) {
      const adjustIdx = ((idx + 1) >= minRacers!)? 0: (idx + 1);
      const adjustBy = (100 - total);

      return setModePrizeDistributionRules(modeIdx, adjustIdx, (rules[adjustIdx]?? 0) + adjustBy, minRacers!, rules);
    }

    modes[modeIdx].prizeDistributionRules = rules;
    modes[modeIdx].minRacers = minRacers;

    setRaceConfig({ ...raceConfig, tournaments:  { ...raceConfig.tournaments, modes }});
    return rules;
  };


  const setPayoutPrizeDistributionRules = (sbIdx: number, idx: number, value: number, minRacers?: number, distribution?: number[]): number[] => {
    const prizeConfigForCycle = getPrizeConfigForCycle(sbIdx);
    minRacers = minRacers?? prizeConfigForCycle.rules.numberOfPrizes;
    distribution = distribution?? prizeConfigForCycle.rules.prizeDistributionRules;

    if((minRacers?? 0) < 1) {
      return [] as number[];
   }
    /* ^get out if things are off^ */

    const rules = distribution!.slice(0, minRacers!);

    value = ( (idx + 1) > minRacers! )? 0: ( (value < 0)? 0: value );

    rules[idx] = value;

    const total = rules.reduce((acc: number, item: number) => acc + item, 0);
    if(total !== 100) {
      const adjustIdx = ((idx + 1) >= minRacers!)? 0: (idx + 1);
      const adjustBy = (100 - total);

      return setPayoutPrizeDistributionRules(sbIdx, adjustIdx, (rules[adjustIdx]?? 0) + adjustBy, minRacers!, rules);
    }

    editCustomScorebaordCycleItem(sbIdx, { prizes: { ...prizeConfigForCycle, rules: { ...prizeConfigForCycle.rules, numberOfPrizes: minRacers, prizeDistributionRules: rules } } });

    return rules;
  };


  useEffect(() => {
    console.log({ space });
    setIsRaceable(space?.isRaceable?? false);
    getRaceConfig().then(() => {
      setIsLoading(false);
    });
  }, []);

  const setPointXYZ = (point: Point, d: 'x' | 'y' | 'z', value: number): Point => {
    const newPoint = (() => {
      switch(d) {
        case 'x': return [value, ...point.slice(1)];
        case 'y': return [...point.slice(0, 1), value, ...point.slice(2)];
        case 'z': return [point[0], point[1], value];
        default:  return [value, ...point.slice(1)];
      }
    })();
    return newPoint as Point;
  };

  const setCuboidPointXYZ = (cuboid: Cuboid, vertexIdx: number, d: 'x' | 'y' | 'z', value: number): Cuboid => {
    cuboid[vertexIdx] = setPointXYZ(cuboid[vertexIdx], d, value);
    return cuboid;
  };

  const toggleVertex = (state: boolean[], i: number) => {
    state[i] = !state[i];
    return state;
  };

  const toggleStartLineVertex = (i: number) => {
    setShowStartLineVertex([...toggleVertex(showStartLineVertex, i)]);
  };

  const toggleFinishLineVertex = (i: number) => {
    setShowFinishLineVertex([...toggleVertex(showFinishLineVertex, i)]);
  };

  const toggleWinnerLocations = (i: number) => {
    const sl = [...Array(raceConfig.podium.playerAnimations.length).keys()].map((i) => {
      return false;
    });

    sl[i] = !(showPodiumWinnerLocations?.[i]?? false);

    setShowPodiumWinnerLocations([...sl]);
  }

  const toggleSartLanes = (i: number, type: 'location' | 'rotation') => {
    const def = { location: false, rotation: false };

    console.log({ slShowStartLines: showStartLanes });

    const sl = [...Array(raceConfig.maxRacers).keys()].map((i) => {
      return { location: false, rotation: false };;
    });


    if(type === 'location') {
      sl[i].location = !(showStartLanes?.[i]?.location?? def.location);
    } else {
      sl[i].rotation = !(showStartLanes?.[i]?.rotation?? def.rotation);
    }

    setShowStartLanes([...sl]);
  };


  const setStartLane = (i: number, type: 'location' | 'rotation', d: 'x' | 'y' | 'z', value: number) => {
    const def = { location: [0,0,0], rotation: [0,0,0] };
    const rc = [...(raceConfig?.startLine.lanes?? [])];
    rc[i] = rc?.[i]?? def;
    rc[i][type] = setPointXYZ(rc[i][type], d, value);
    setRaceConfig({ ...raceConfig, startLine: { ...raceConfig.startLine, lanes: [...rc] } })
  };

  const setPodiumWinnerLocation = (i: number, type: 'location' | 'rotation', d: 'x' | 'y' | 'z', value: number) => {
    const def = { location: [0,0,0], rotation: [0,0,0] };
    const rc = [...(raceConfig?.podium.winnerLocations?? [])];
    rc[i] = rc?.[i]?? def;
    rc[i][type] = setPointXYZ(rc[i][type], d, value);
    setRaceConfig({ ...raceConfig, podium: { ...raceConfig.podium, winnerLocations: [...rc] } })
  };

  const getTournamentModes = () => {
    return raceConfig?.tournaments?.modes?? defaultRaceConfig.tournaments.modes;
  }

  const setTournamentModeProp = (idx: number, prop: string, value: any) => {
    const modes = [...getTournamentModes()];
    if(!modes[idx]) return;

    value = (() => {
      if(['stationOwnerFeePercentage', 'nowhereFeePercentage'].includes(prop)) {
        if(value > 15) return 15;
        if(value < 0) return 0;
        return value;
      }
      return value;
    })();

    modes[idx][prop] = value;

    setRaceConfig({ ...raceConfig, tournaments: { ...raceConfig.tournaments, modes } });
  };

  const getShowTMPrizeRules = (idx: number) => {
    return showTMPrizeRules[idx]?? false;
  }

  const toggleShowTMPrizeRules = (idx: number) => {
    const pr = !getShowTMPrizeRules(idx);
    const prs = showTMPrizeRules;
    prs[idx] = pr;
    setShowTMPrizeRules([...prs]);
    console.log(showTMPrizeRules);
  }

  const getShowPrizePrizeRules = (idx: number) => {
    return showPrizePrizeRules[idx]?? false;
  }

  const toggleShowPrizePrizeRules = (idx: number) => {
    const pr = !getShowPrizePrizeRules(idx);
    const prs = showPrizePrizeRules;
    prs[idx] = pr;
    setShowPrizePrizeRules([...prs]);
    console.log(showPrizePrizeRules);
  }

  const addRemoveTournamentMode = (isAdd = true) => {
    const _modes = [...getTournamentModes()];

    if(!isAdd && _modes.length < 2) return;
    if(!isAdd) {
      setRaceConfig({ ...raceConfig, tournaments: { ...raceConfig.tournaments, modes: [..._modes.slice(0, -1)] } });
      return;
    }

    const newMode = {
      id: uuid(),
      name: '',
      description: '',
      fee: 100,
      timeout: 5,
      minRacers: 3,
      prizeDistributionRules: [60, 30, 10],
      nowhereFeePercentage: 10,
      stationOwnerFeePercentage: 10,
    };

     setRaceConfig({ ...raceConfig, tournaments: { ...raceConfig.tournaments, modes: [..._modes.concat(newMode)] } });
     return;
  }

  return (
    <>
      {!isLoading && <Form
          id="RaceConfig-Form"
          name="RaceConfigForm"
          data-name="Race Configuration"
          className="RaceFormClass"
          onSubmit={handleSubmit(handleRaceConfigUpdate)}
        >
        <FCon className="noTranslate">
           <Headliner>Race Configuration</Headliner>
           <Divider />
            <ButtonContainer>
              <WhiteLinkText onClick={() => onSubmitSuccess()}>Cancel</WhiteLinkText>
              <Runners>🏃🏿🏃 🏃‍♂️</Runners>
              <AuthButton id="saveButton" disabled={submitting}>
                Save
              </AuthButton>
            </ButtonContainer>
           <Divider />
           {canManageRacingSubsection(user, space, 'racing') && <Row>
              <Label>Racing</Label>
              <Field>
                <LabeledToggleWrapper>
                  <LabeledToggle
                      leftLabel="Allow"
                      rightLabel="Disallow"
                      value={isRaceable}
                      changeHandler={(value) => setIsRaceable(!isRaceable)}
                  />
                </LabeledToggleWrapper>
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Allow / Disallow racing in this space"
                    src={InfoIcon}
                />
              </Desc>
           </Row>}

          {isRaceable && <>
           {canManageRacingSubsection(user, space, 'metadata') && <>
              <Divider />
              <h3 onClick={ () => toggleHeading('metadata') }>{ headingToggles['metadata'] && <>▼</> }{ !headingToggles['metadata'] && <>▶</> } Metadata</h3>
              <Divider />
            </>}
          { headingToggles['metadata'] && <>
            <Row>
                <Label>Title</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="md-title"
                      name="md-title"
                      onChange={(value: any) => setRaceConfig({ ...raceConfig, title: value.target.value })}
                      type="text"
                      validation={register({})}
                      value={raceConfig.title}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Enter a title for the race"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>

             <Row>
                <Label>Description</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="md-description"
                      name="md-description"
                      onChange={(value: any) => setRaceConfig({ ...raceConfig, description: value.target.value })}
                      type="text"
                      validation={register({})}
                      value={raceConfig.description}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Enter a description for the race"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>

            <Row>
                <Label>Logo</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="md-logo"
                      name="md-logo"
                      onChange={(value: any) => setRaceConfig({ ...raceConfig, logo: value.target.value })}
                      type="text"
                      validation={register({})}
                      value={raceConfig.logo}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Enter a URL for the racelLogo image"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>

             <Row>
                <Label>Instructions</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="md-instructions"
                      name="md-instructions"
                      onChange={(value: any) => setRaceConfig({ ...raceConfig, instructions: value.target.value })}
                      type="textarea"
                      validation={register({})}
                      value={raceConfig.instructions}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Enter instructions for the race"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
          </>}
          {canManageRacingSubsection(user, space, 'autoCalibration') && <>
             <Divider />
             <h3 onClick={ () => toggleHeading('autoCalibration') }>{ headingToggles['autoCalibration'] && <>▼</> }{ !headingToggles['autoCalibration'] && <>▶</> } Auto Calibration</h3>
             <Divider />
          </>}
           { headingToggles['autoCalibration'] && <>
             <Row>
                <Label>Entry Circle</Label>
                <Field>
                  <LabeledToggleWrapper>
                    <LabeledToggle
                        leftLabel="Allow"
                        rightLabel="Disallow"
                        value={raceConfig.autoCalibration.entryCircle}
                        changeHandler={(value) => setRaceConfig({ ...raceConfig, autoCalibration: { ...raceConfig.autoCalibration, entryCircle: !raceConfig.autoCalibration.entryCircle } })}
                    />
                  </LabeledToggleWrapper>
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Allow / Disallow Automatic Configration (if possible) of entry circle location, radius, etc..."
                      src={InfoIcon}
                  />
                </Desc>
             </Row>

             <Row>
                <Label>Tournament Circle</Label>
                <Field>
                  <LabeledToggleWrapper>
                    <LabeledToggle
                        leftLabel="Allow"
                        rightLabel="Disallow"
                        value={raceConfig.autoCalibration.tournamentEntryCircle}
                        changeHandler={(value) => setRaceConfig({ ...raceConfig, autoCalibration: { ...raceConfig.autoCalibration, tournamentEntryCircle: !raceConfig.autoCalibration.tournamentEntryCircle } })}
                    />
                  </LabeledToggleWrapper>
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Allow / Disallow Automatic Configration (if possible) of tournament entry circle location, radius, etc..."
                      src={InfoIcon}
                  />
                </Desc>
             </Row>

             <Row>
                <Label>Starting Line</Label>
                <Field>
                  <LabeledToggleWrapper>
                    <LabeledToggle
                        leftLabel="Allow"
                        rightLabel="Disallow"
                        value={raceConfig.autoCalibration.startingLine}
                        changeHandler={(value) => setRaceConfig({ ...raceConfig, autoCalibration: { ...raceConfig.autoCalibration, startingLine: !raceConfig.autoCalibration.startingLine } })}
                    />
                  </LabeledToggleWrapper>
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Allow / Disallow Automatic Configration (if possible) of startingLine location, lanes, etc..."
                      src={InfoIcon}
                  />
                </Desc>
             </Row>

             <Row>
                <Label>Finish Line</Label>
                <Field>
                  <LabeledToggleWrapper>
                    <LabeledToggle
                        leftLabel="Allow"
                        rightLabel="Disallow"
                        value={raceConfig.autoCalibration.finishLine}
                        changeHandler={(value) => setRaceConfig({ ...raceConfig, autoCalibration: { ...raceConfig.autoCalibration, finishLine: !raceConfig.autoCalibration.finishLine } })}
                    />
                  </LabeledToggleWrapper>
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Allow / Disallow Automatic Configration (if possible) of finish line location."
                      src={InfoIcon}
                  />
                </Desc>
             </Row>

             <Row>
                <Label>Scorers Table</Label>
                <Field>
                  <LabeledToggleWrapper>
                    <LabeledToggle
                        leftLabel="Allow"
                        rightLabel="Disallow"
                        value={raceConfig.autoCalibration.scorersTable}
                        changeHandler={(value) => setRaceConfig({ ...raceConfig, autoCalibration: { ...raceConfig.autoCalibration, scorersTable: !raceConfig.autoCalibration.scorersTable } })}
                    />
                  </LabeledToggleWrapper>
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Allow / Disallow Automatic Configration (if possible) of scorers table location."
                      src={InfoIcon}
                  />
                </Desc>
             </Row>

             <Row>
                <Label>Podium</Label>
                <Field>
                  <LabeledToggleWrapper>
                    <LabeledToggle
                        leftLabel="Allow"
                        rightLabel="Disallow"
                        value={raceConfig.autoCalibration.podium}
                        changeHandler={(value) => setRaceConfig({ ...raceConfig, autoCalibration: { ...raceConfig.autoCalibration, podium: !raceConfig.autoCalibration.podium } })}
                    />
                  </LabeledToggleWrapper>
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Allow / Disallow Automatic Configration (if possible) of podium placements."
                      src={InfoIcon}
                  />
                </Desc>
             </Row>

             <Row>
                <Label>Race Complete</Label>
                <Field>
                  <LabeledToggleWrapper>
                    <LabeledToggle
                        leftLabel="Allow"
                        rightLabel="Disallow"
                        value={raceConfig.autoCalibration.raceComplete}
                        changeHandler={(value) => setRaceConfig({ ...raceConfig, autoCalibration: { ...raceConfig.autoCalibration, raceComplete: !raceConfig.autoCalibration.raceComplete } })}
                    />
                  </LabeledToggleWrapper>
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Allow / Disallow Automatic Configration (if possible) of race complete teleport location."
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
           </>}
           {canManageRacingSubsection(user, space, 'automaticRaceConfig') && <>
             <Divider />
             <h3 onClick={ () => toggleHeading('automaticRaceConfig') }>{ headingToggles['automaticRaceConfig'] && <>▼</> }{ !headingToggles['automaticRaceConfig'] && <>▶</> } Automatic Race Config</h3>
             <Divider />
           </>}
           { headingToggles['automaticRaceConfig'] && <>
            <Row>
              <Label>Automatic Racing</Label>
              <Field>
                <LabeledToggleWrapper>
                  <LabeledToggle
                      leftLabel="Allow"
                      rightLabel="Disallow"
                      value={raceConfig.isAutoRace}
                      changeHandler={(value) => setRaceConfig({ ...raceConfig, isAutoRace: !raceConfig.isAutoRace })}
                  />
                </LabeledToggleWrapper>
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Allow / Disallow races to start automatically"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           {raceConfig.isAutoRace && <Row>
                <Label>Start Interval</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="startInterval"
                      name="startInterval"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, startInterval: parseInt(value.target.value) } )}
                      type="number"
                      validation={register({ required: 'Start Interval is required' })}
                      value={raceConfig.startInterval}
                      style={{ width: '78px' }}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Will start a race every X minutes if automatic racing is enabled"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
           }
           </>}
           {canManageRacingSubsection(user, space, 'racerRestrictions') && <>
             <Divider />
             <h3 onClick={ () => toggleHeading('racerRestrictions') }>{ headingToggles['racerRestrictions'] && <>▼</> }{ !headingToggles['racerRestrictions'] && <>▶</> } Racer Restrictions</h3>
             <Divider />
           </>}
           { headingToggles['racerRestrictions'] && <>
             <Row>
                <Label>Double Jump</Label>
                <Field>
                  <LabeledToggleWrapper>
                    <LabeledToggle
                        leftLabel="Allow"
                        rightLabel="Disallow"
                        value={raceConfig.isDoubleJumpEnabled}
                        changeHandler={(value) => setRaceConfig({ ...raceConfig, isDoubleJumpEnabled: !raceConfig.isDoubleJumpEnabled })}
                    />
                  </LabeledToggleWrapper>
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Allow / Disallow racers to double jump"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             <Row>
                <Label>Blast-Off</Label>
                <Field>
                  <LabeledToggleWrapper>
                    <LabeledToggle
                        leftLabel="Allow"
                        rightLabel="Disallow"
                        value={raceConfig.isBlastOffEnabled}
                        changeHandler={(value) => setRaceConfig({ ...raceConfig, isBlastOffEnabled: !raceConfig.isBlastOffEnabled})}
                    />
                  </LabeledToggleWrapper>
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Allow / Disallow racers to blast-off"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             <Row>
                <Label>Location Sharing</Label>
                <Field>
                  <LabeledToggleWrapper>
                    <LabeledToggle
                        leftLabel="Allow"
                        rightLabel="Disallow"
                        value={raceConfig.isLocationSharingEnabled}
                        changeHandler={(value) => setRaceConfig({ ...raceConfig, isLocationSharingEnabled: !raceConfig.isLocationSharingEnabled})}
                    />
                  </LabeledToggleWrapper>
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Allow / Disallow racers share location"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
           </>}
           {canManageRacingSubsection(user, space, 'raceDynamics') && <>
             <Divider />
             <h3 onClick={ () => toggleHeading('raceDynamics') }>{ headingToggles['raceDynamics'] && <>▼</> }{ !headingToggles['raceDynamics'] && <>▶</> } Race Dynamics</h3>
             <Divider />
           </>}
           { headingToggles['raceDynamics'] && <>
             <Row>
                <Label>Fixed Speed</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="fixedSpeed"
                      name="fixedSpeed"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, fixedSpeed: parseFloat(value.target.value) } )}
                      type="number"
                      validation={register({ required: 'Fixed Speed is required' })}
                      value={raceConfig.fixedSpeed}
                      style={{ width: '78px' }}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Sets the racers speed; you can use any value < 1 for free player movement"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             <Row>
                <Label>Max Racers</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="maxRacers"
                      name="maxRacers"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, maxRacers: parseInt(value.target.value) } )}
                      type="number"
                      validation={register({ required: 'Max Racers is required' })}
                      value={raceConfig.maxRacers}
                      style={{ width: '78px' }}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Set the maximum racers allowed in a single race"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             <Row>
                <Label>Max Duration</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="maxDuration"
                      name="maxDuration"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, maxDuration: parseInt(value.target.value) } )}
                      type="number"
                      validation={register({ required: 'Max Duration is required' })}
                      value={raceConfig.maxDuration}
                      style={{ width: '100px' }}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Set the maximum race durration (seconds) in a single race"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             <Row>
                <Label>Min Duration</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="minDuration"
                      name="minDuration"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, minDuration: parseInt(value.target.value) } )}
                      type="number"
                      validation={register({ required: 'Min Duration is required' })}
                      value={raceConfig.minDuration}
                      style={{ width: '100px' }}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Set the min race durration (seconds) for a single racer"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             <Row>
                <Label>Number Of Laps</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="numberOfLaps"
                      name="numberOfLaps"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, numberOfLaps: parseInt(value.target.value) } )}
                      type="number"
                      validation={register({ required: 'Number Of Laps is required' })}
                      value={raceConfig.numberOfLaps}
                      style={{ width: '78px' }}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Number of laps to race"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             {(raceConfig.numberOfLaps > 1) && <Row>
                  <Label>Lap Acceleration</Label>
                  <Field>
                   <ValidatedFormInput
                        labelText=""
                        className="w-input"
                        errors={errors}
                        id="lapAcceleration"
                        name="lapAcceleration"
                        onChange={(value: any) => setRaceConfig( { ...raceConfig, lapAcceleration: parseInt(value.target.value) } )}
                        type="number"
                        validation={register({ required: 'Lap Acceleration is required' })}
                        value={raceConfig.lapAcceleration}
                        style={{ width: '78px' }}
                    />
                  </Field>
                  <Desc>
                    <ReactTooltip effect="solid" />
                    <InactiveInfo
                        data-tip="% to increase / Decrease speed every lap in a fixedSpeed race"
                        src={InfoIcon}
                    />
                  </Desc>
               </Row>
           }
           </>}
           {canManageRacingSubsection(user, space, 'raceModes') && <>
             <Divider />
             <h3 onClick={ () => toggleHeading('raceModes') }>{ headingToggles['raceModes'] && <>▼</> }{ !headingToggles['raceModes'] && <>▶</> } Race Modes</h3>
             <Divider />
           </>}
           { headingToggles['raceModes'] && <>
             {raceConfig.modes.map((m: { [key: string]: any }, i: number) => (
             <>
             <h5>Race #{i + 1}:</h5>
             <Row>
                <Label>Mode Name</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="er-name"
                      name="er-name"
                      onChange={(value: any) => { setRaceConfig({ ...updateModes(i, { ...m, name: value.target.value } )})}}
                      type="text"
                      validation={register({})}
                      value={m.name}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Enter the Name of the game mode"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             <Row>
                <Label>Mode Description</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="er-desc"
                      name="er-desc"
                      onChange={(value: any) => { setRaceConfig({ ...updateModes(i, { ...m, description: value.target.value } )})}}
                      type="text"
                      validation={register({})}
                      value={m.description}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Enter the description of the game mode to be shown to users"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             <Row>
                <Label>Game Item Required</Label>
                <Field>
                  <LabeledToggleWrapper>
                    <LabeledToggle
                        leftLabel="True"
                        rightLabel="False"
                        value={m.isGameItemRequired}
                        changeHandler={(value) => setRaceConfig({...updateModes(i, { ...m, isGameItemRequired: value })})}
                    />
                  </LabeledToggleWrapper>
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Require racers to have a game item to race"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             {raceConfig.modes[i].isGameItemRequired && <>
               <Row>
                  <Label>Game Item ID</Label>
                  <Field>
                   <ValidatedFormInput
                        labelText=""
                        className="w-input"
                        errors={errors}
                        id="er-gameItemId"
                        name="er-gameItemId"
                        onChange={(value: any) => { setRaceConfig({ ...updateModes(i, { ...m, gameItemId: value.target.value } )})}}
                        type="text"
                        validation={register({})}
                        value={m.gameItemId}
                    />
                  </Field>
                  <Desc>
                    <ReactTooltip effect="solid" />
                    <InactiveInfo
                        data-tip="Enter the Game Item ID that is required to race"
                        src={InfoIcon}
                    />
                  </Desc>
               </Row>
               <Row>
                  <Label>Is Game Item Burrend After Racing</Label>
                  <Field>
                    <LabeledToggleWrapper>
                      <LabeledToggle
                          leftLabel="True"
                          rightLabel="False"
                          value={m.isBurnedAfterRacing}
                          changeHandler={(value) => setRaceConfig({...updateModes(i, { ...m, isBurnedAfterRacing: value })})}
                      />
                    </LabeledToggleWrapper>
                  </Field>
                  <Desc>
                    <ReactTooltip effect="solid" />
                    <InactiveInfo
                        data-tip="Game Item will be burned and removed from the racers inventory after race"
                        src={InfoIcon}
                    />
                  </Desc>
               </Row>
             </>
             }
             {(i < (raceConfig.modes.length - 1)) && <Divider /> }
             </>
             ))}
             <ButtonContainer>
               {(raceConfig.modes.length > 1) && <><AuthButton id="RemoveMode" onClick={(event) => { event.preventDefault(); setRaceConfig({ ...updateModes(raceConfig.modes.length -1) });}} disabled={submitting}>
                    -
               </AuthButton>
               &nbsp; &nbsp;
               </>}
               <AuthButton id="AddMode" onClick={(event) => { event.preventDefault(); setRaceConfig({ ...updateModes(raceConfig.modes.length) }); }} disabled={submitting}>
                    +
               </AuthButton>
             </ButtonContainer>
           </>}

           {canManageRacingSubsection(user, space, 'tournaments') && <>
             <Divider />
             <h3 onClick={ () => toggleHeading('tournaments') }>{ headingToggles['tournaments'] && <>▼</> }{ !headingToggles['tournaments'] && <>▶</> } Tournaments</h3>
             <Divider />
           </>}
           { headingToggles['tournaments'] && <>
             <Row>
              <Label>Enable</Label>
              <Field>
                <LabeledToggleWrapper>
                  <LabeledToggle
                      leftLabel="Yes"
                      rightLabel="No"
                      value={raceConfig.tournaments.isEnabled}
                      changeHandler={(value) => setRaceConfig({ ...raceConfig, tournaments: { ...raceConfig.tournaments, isEnabled: !raceConfig.tournaments.isEnabled } })}
                  />
                </LabeledToggleWrapper>
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Enable / Disable Tournaments"
                    src={InfoIcon}
                />
              </Desc>
           </Row>

          { raceConfig.tournaments.isEnabled && <>
            <Row>
              <Label>Provider</Label>
              <Field>
                <Select
                  onChange={(value) => setRaceConfig({ ...raceConfig, tournaments: { ...raceConfig.tournaments, provider: value } })}
                  value={raceConfig.tournaments.provider}
                  options={tournamentProviders}
                  spClassName="noTranslate"
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Select the tournament API provider"
                    src={InfoIcon}
                />
              </Desc>
           </Row>

            <Row>
              <Label>Currency</Label>
              <Field>
                <Select
                  onChange={(value) => setRaceConfig({ ...raceConfig, tournaments: { ...raceConfig.tournaments, currency: value } })}
                  value={raceConfig.tournaments.currency}
                  options={tournamentCurrencies}
                  spClassName="noTranslate"
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Select the tournament currency to be used to pay entry fees"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           {canManageRacingSubsection(user, space, 'tournamentsEntryCircle') && <>
             <br/>
             <h4>Tournament Entry Circle</h4>
              <Divider />

              <Row>
                <Label>Radius</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="tourc-radius"
                      name="tourc-radius"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, tournaments: {
                        ...raceConfig.tournaments,
                        entryCircle: { ...raceConfig.tournaments.entryCircle, radius: parseFloat(value.target.value) }
                      }})}
                      type="number"
                      validation={register({})}
                      value={raceConfig.tournaments.entryCircle.radius}
                      style={{ width: '100px' }}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Radius of the entry circle"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             <Row>
                <Label>Color</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="tourc-color"
                      name="tourc-color"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, tournaments: {
                        ...raceConfig.tournaments,
                        entryCircle: { ...raceConfig.tournaments.entryCircle, color: value.target.value }
                      }})}
                      type="text"
                      validation={register({})}
                      value={raceConfig.tournaments.entryCircle.color}
                      style={{ width: '100px' }}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Color of the tournament entry circle in hex"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             <Row>
                <Label>Texture</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="tourc-texture"
                      name="tourc-texture"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, tournaments: {
                        ...raceConfig.tournaments,
                        entryCircle: { ...raceConfig.tournaments.entryCircle, texture: parseInt(value.target.value) }
                      }})}
                      type="text"
                      validation={register({})}
                      value={raceConfig.tournaments.entryCircle.texture}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Url to the texture of the tournament entry circle"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             <Row>
                <Label>Location</Label>
                <Field>
                  <div onClick={ () => setShowTournamentEntryCircleLoc(!showTournamentEntryCircleLoc) }>
                    { JSON.stringify(raceConfig.tournaments.entryCircle.location).replaceAll('"', '') }
                  </div>


                 { showTournamentEntryCircleLoc && <>X:<ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="tourc-location-x"
                      name="tourc-location-x"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, tournaments: {
                        ...raceConfig.tournaments,
                        entryCircle: { ...raceConfig.tournaments.entryCircle, location: setPointXYZ(raceConfig.tournaments.entryCircle.location, 'x', parseFloat(value.target.value)) }
                      }})}
                      type="number"
                      validation={register({})}
                      value={raceConfig.tournaments.entryCircle.location[0]}
                  />
                 Y:<ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="tourc-location-y"
                      name="tourc-location-y"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, tournaments: {
                        ...raceConfig.tournaments,
                        entryCircle: { ...raceConfig.tournaments.entryCircle, location: setPointXYZ(raceConfig.tournaments.entryCircle.location, 'y', parseFloat(value.target.value)) }
                      }})}
                      type="number"
                      validation={register({})}
                      value={raceConfig.tournaments.entryCircle.location[1]}
                  />
                  Z:<ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="tourc-location-z"
                      name="tourc-location-z"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, tournaments: {
                        ...raceConfig.tournaments,
                        entryCircle: { ...raceConfig.tournaments.entryCircle, location: setPointXYZ(raceConfig.tournaments.entryCircle.location, 'z', parseFloat(value.target.value)) }
                      }})}
                      type="number"
                      validation={register({})}
                      value={raceConfig.tournaments.entryCircle.location[2]}
                  />
                </>}
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Tournament Entry Point Coordinates Value"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>
             <br/>
            </>}
            <h4>Tournament Modes</h4>
            <Divider />

            {[...Array(getTournamentModes().length).keys()].map((i) => (
              <>
                { (i > 0) && <Divider /> }
                <h5>Race #{i + 1}:</h5>
                <Row>
                  <Label>Name</Label>
                  <Field>
                   <ValidatedFormInput
                        labelText=""
                        className="w-input"
                        errors={errors}
                        id={`tourcmd-name-${i}`}
                        name={`tourcmd-name-${i}`}
                        onChange={(value: any) => setTournamentModeProp(i, 'name', value.target.value)}
                        type="text"
                        validation={register({})}
                        value={getTournamentModes()[i].name}
                        style={{}}
                    />
                  </Field>
                  <Desc>
                    <ReactTooltip effect="solid" />
                    <InactiveInfo
                        data-tip="Name of the tournament mode"
                        src={InfoIcon}
                    />
                  </Desc>
               </Row>

               <Row>
                  <Label>Description</Label>
                  <Field>
                   <ValidatedFormInput
                        labelText=""
                        className="w-input"
                        errors={errors}
                        id={`tourcmd-deec-${i}`}
                        name={`tourcmd-deec-${i}`}
                        onChange={(value: any) => setTournamentModeProp(i, 'description', value.target.value)}
                        type="textarea"
                        validation={register({})}
                        value={getTournamentModes()[i].description}
                        style={{}}
                    />
                  </Field>
                  <Desc>
                    <ReactTooltip effect="solid" />
                    <InactiveInfo
                        data-tip="Description of the tournament mode"
                        src={InfoIcon}
                    />
                  </Desc>
               </Row>

               <Row>
                  <Label>Entry Fee</Label>
                  <Field>
                   <ValidatedFormInput
                        labelText=""
                        className="w-input"
                        errors={errors}
                        id={`tourcmd-entry-fee-${i}`}
                        name={`tourcmd-entry-fee-${i}`}
                        onChange={(value: any) => {
                          const fee = parseFloat(value.target.value);
                          if(fee > 0) setTournamentModeProp(i, 'fee', fee);
                        }}
                        type="number"
                        validation={register({})}
                        value={getTournamentModes()[i].fee}
                        style={{ width: '100px' }}
                    />
                  </Field>
                  <Desc>
                    <ReactTooltip effect="solid" />
                    <InactiveInfo
                        data-tip="The entry fee for each racers in the tournament"
                        src={InfoIcon}
                    />
                  </Desc>
               </Row>

               <Row>
                  <Label>Timeout</Label>
                  <Field>
                   <ValidatedFormInput
                        labelText=""
                        className="w-input"
                        errors={errors}
                        id={`tourcmd-timeout-${i}`}
                        name={`tourcmd-timeout-${i}`}
                        onChange={(value: any) => {
                          const to = parseInt(value.target.value);
                          if(to > 0) setTournamentModeProp(i, 'timeout', to);
                        }}
                        type="number"
                        validation={register({})}
                        value={getTournamentModes()[i].timeout}
                        style={{ width: '100px' }}
                    />
                  </Field>
                  <Desc>
                    <ReactTooltip effect="solid" />
                    <InactiveInfo
                        data-tip="Tournament will timeout in X minutes if the minimum racers requirement has not been met "
                        src={InfoIcon}
                    />
                  </Desc>
               </Row>

               <Row>
                  <Label>Minimum Racers</Label>
                  <Field>
                   <ValidatedFormInput
                        labelText=""
                        className="w-input"
                        errors={errors}
                        id={`tourcmd-min-racers-${i}`}
                        name={`tourcmd-min-racers-${i}`}
                        onChange={(value: any) => {
                          const mr = parseInt(value.target.value);
                          if(mr > 0) setModePrizeDistributionRules(i, 0, getTournamentModes()[i].prizeDistributionRules[0], parseInt(value.target.value));
                        }}
                        type="number"
                        validation={register({})}
                        value={getTournamentModes()[i].minRacers}
                        style={{ width: '100px' }}
                    />
                  </Field>
                  <Desc>
                    <ReactTooltip effect="solid" />
                    <InactiveInfo
                        data-tip="The minimum number of racers required to start a tournament"
                        src={InfoIcon}
                    />
                  </Desc>
               </Row>

              <Row>
              <Label>Prize Distribution</Label>
              <Field>
                <div onClick={ () => toggleShowTMPrizeRules(i)}>
                  { JSON.stringify(getTournamentModes()[i].prizeDistributionRules).replaceAll('"', '') }
                </div>
                { getShowTMPrizeRules(i) && <>
                  {[...Array(getTournamentModes()[i].minRacers).keys()].map((plIdx) => (
                    <>
                      <span>Place #{plIdx + 1}: </span>
                      <ValidatedFormInput
                        labelText=""
                        className="w-input"
                        errors={errors}
                        id={`tourcmd-pdr-${i}-${plIdx}`}
                        name={`tourcmd-pdr-${i}-${plIdx}`}
                        onChange={(value: any) => {
                          setModePrizeDistributionRules(i, plIdx, parseFloat(value.target.value));
                        }}
                        type="number"
                        validation={register({})}
                        value={getTournamentModes()[i].prizeDistributionRules[plIdx]}
                        style={{ width: '100px' }}
                    />
                    </>
                  ))}
                </>}

              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Set the % of the prize money to be sent to top finishers [1st, 2nd, 3rd, etc..]"
                    src={InfoIcon}
                />
              </Desc>
            </Row>

            <Row>
              <Label>Prize Allocation % + 5% Nowhere Fee</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id={`tourcmd-nfp-{i}`}
                    name={`tourcmd-nfp-{i}`}
                    onChange={(value: any) => setTournamentModeProp(i, 'nowhereFeePercentage', parseFloat(value.target.value))}
                    type="number"
                    validation={register({})}
                    value={getTournamentModes()[i].nowhereFeePercentage}
                    style={{ width: '100px' }}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Enter Nowhere's cut of the fees"
                    src={InfoIcon}
                />
              </Desc>
            </Row>

            <Row>
              <Label>St. Owner Fee %</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id={`tourcmd-sfop-{i}`}
                    name={`tourcmd-sfop-{i}`}
                    onChange={(value: any) => setTournamentModeProp(i, 'stationOwnerFeePercentage', parseFloat(value.target.value))}
                    type="number"
                    validation={register({})}
                    value={getTournamentModes()[i].stationOwnerFeePercentage}
                    style={{ width: '100px' }}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Enter Nowhere's cut of the fees"
                    src={InfoIcon}
                />
              </Desc>
            </Row>
             </>
            ))}

            <ButtonContainer>
             {(getTournamentModes().length > 1) && <><AuthButton id="RemoveMode" onClick={(event) => { event.preventDefault(); addRemoveTournamentMode(false);}} disabled={submitting}>
                  -
             </AuthButton>
             </>}
             &nbsp; &nbsp;
             <AuthButton id="AddMode" onClick={(event) => { event.preventDefault(); addRemoveTournamentMode(true); }} disabled={submitting}>
                  +
             </AuthButton>
           </ButtonContainer>
           <br/>
          </>}
          </>}
          {canManageRacingSubsection(user, space, 'entryCircle') && <>
            <Divider />
            <h3 onClick={ () => toggleHeading('entryCircle') }>{ headingToggles['entryCircle'] && <>▼</> }{ !headingToggles['entryCircle'] && <>▶</> } Entry Circle</h3>
            <Divider />
          </>}
           { headingToggles['entryCircle'] && <>
           <Row>
              <Label>Radius</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="ec-radius"
                    name="ec-radius"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, entryCircle: {
                      ...raceConfig.entryCircle,
                      radius: parseInt(value.target.value)
                    }})}
                    type="number"
                    validation={register({ required: 'Entry Circle Radius is required' })}
                    value={raceConfig.entryCircle.radius}
                    style={{ width: '100px' }}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Radius of the entry circle"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           <Row>
              <Label>Color</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="ec-color"
                    name="ec-color"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, entryCircle: {
                      ...raceConfig.entryCircle,
                      color: value.target.value
                    }})}
                    type="text"
                    validation={register({})}
                    value={raceConfig.entryCircle.color}
                    style={{ width: '100px' }}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Color of the entry circle in hex"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           <Row>
              <Label>Texture</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="ec-texture"
                    name="ec-texture"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, entryCircle: {
                      ...raceConfig.entryCircle,
                      texture: parseInt(value.target.value)
                    }})}
                    type="text"
                    validation={register({})}
                    value={raceConfig.entryCircle.texture}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Url to the texture of the entry circle"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           <Row>
              <Label>Location</Label>
              <Field>
                <div onClick={ () => setShowEntryCircleLoc(!showEntryCircleLoc) }>
                  { JSON.stringify(raceConfig.entryCircle.location).replaceAll('"', '') }
                </div>


               { showEntryCircleLoc && <>X:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="ec-location-x"
                    name="ec-location-x"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, entryCircle: {
                      ...raceConfig.entryCircle,
                      location: setPointXYZ(raceConfig.entryCircle.location, 'x', parseFloat(value.target.value))
                    }})}
                    type="number"
                    validation={register({ required: 'Entry Circle Location is required' })}
                    value={raceConfig.entryCircle.location[0]}
                />
               Y:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="ec-location-y"
                    name="ec-location-y"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, entryCircle: {
                      ...raceConfig.entryCircle,
                      location: setPointXYZ(raceConfig.entryCircle.location, 'y', parseFloat(value.target.value))
                    }})}
                    type="number"
                    validation={register({ required: 'Entry Circle Location is required' })}
                    value={raceConfig.entryCircle.location[1]}
                />
                Z:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="ec-location-z"
                    name="ec-location-z"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, entryCircle: {
                      ...raceConfig.entryCircle,
                      location: setPointXYZ(raceConfig.entryCircle.location, 'z', parseFloat(value.target.value))
                    }})}
                    type="number"
                    validation={register({ required: 'Entry Circle Location is required' })}
                    value={raceConfig.entryCircle.location[2]}
                />
              </>}
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Entry Point Coordinates Value"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           </>}

           {canManageRacingSubsection(user, space, 'laneAssignment') && <>
             <Divider />
             <h3 onClick={ () => toggleHeading('laneAssignment') }>{ headingToggles['laneAssignment'] && <>▼</> }{ !headingToggles['laneAssignment'] && <>▶</> } Lane Assignment</h3>
             <Divider />
           </>}
            { headingToggles['laneAssignment'] && <>
              <Row>
                  <Label>Duration</Label>
                  <Field>
                   <ValidatedFormInput
                        labelText=""
                        className="w-input"
                        errors={errors}
                        id="md-laneAssignmentDuration"
                        name="md-laneAssignmentDuration"
                        onChange={(value: any) => setRaceConfig({ ...raceConfig, laneAssignmentDuration: value.target.value })}
                        type="number"
                        validation={register({})}
                        value={raceConfig.laneAssignmentDuration}
                    />
                  </Field>
                  <Desc>
                    <ReactTooltip effect="solid" />
                    <InactiveInfo
                        data-tip="Enter the total seconds to allow for lane assignments"
                        src={InfoIcon}
                    />
                  </Desc>
               </Row>
            </>}

           {canManageRacingSubsection(user, space, 'startingGun') && <>
             <Divider />
             <h3 onClick={ () => toggleHeading('startingGun') }>{ headingToggles['startingGun'] && <>▼</> }{ !headingToggles['startingGun'] && <>▶</> } Starting Gun</h3>
             <Divider />
           </>}
           { headingToggles['startingGun'] && <>
           <Row>
              <Label>Time Until Gun</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="sg_timeUntilStart"
                    name="sg_timeUntilStart"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, startingGun: { ...raceConfig.startingGun, timeUntilStart: parseInt(value.target.value) } } )}
                    type="number"
                    validation={register({ required: 'Starting Gun time until start is required' })}
                    value={raceConfig.startingGun.timeUntilStart}
                    style={{ width: '78px' }}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Number of seconds from race start until starting gun"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           <Row>
              <Label>Starting Sound</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="sg_sound"
                    name="sg_sound"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, startingGun: { ...raceConfig.startingGun, sound: value.target.value } } )}
                    type="text"
                    validation={register({})}
                    value={raceConfig.startingGun.sound}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Sound to play when starting gun fires and race starts"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           <Row>
              <Label>Waiting Animation</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="sg_waitingAnimation"
                    name="sg_waitingAnimation"
                    onChange={(value: any) => setRaceConfig({
                      ...raceConfig, startingGun: {
                        ...raceConfig.startingGun, animation: {
                            ...raceConfig.startingGun.animation, waitingAnimation: value.target.value
                         }
                       }
                     } )}
                    type="text"
                    validation={register({})}
                    value={raceConfig.startingGun.animation.waitingAnimation}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Animation to play while racers are waiting for the starting gun to fire"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           <Row>
              <Label>Starting Animation</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="sg_startAnimation"
                    name="sg_startAnimation"
                    onChange={(value: any) => setRaceConfig({
                      ...raceConfig, startingGun: {
                        ...raceConfig.startingGun, animation: {
                            ...raceConfig.startingGun.animation, startAnimation: value.target.value
                         }
                       }
                     } )}
                    type="text"
                    validation={register({})}
                    value={raceConfig.startingGun.animation.startAnimation}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Animation to play when starting gun fires and race starts"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           </>}

           {canManageRacingSubsection(user, space, 'startingLine') && <>
             <Divider />
             <h3 onClick={ () => toggleHeading('startingLine') }>{ headingToggles['startingLine'] && <>▼</> }{ !headingToggles['startingLine'] && <>▶</> } Starting Line</h3>
             <Divider />
           </>}
           { headingToggles['startingLine'] && <>
           <Row>
              <Label>Texture</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="slec-texture"
                    name="slec-texture"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, startLine: {
                      ...raceConfig.startLine,
                      texture: parseInt(value.target.value)
                    }})}
                    type="text"
                    validation={register({})}
                    value={raceConfig.startLine.texture}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Url to the texture of the start line"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           <Row>
              <Label>Cuboid</Label>
              <Field>
               {[...Array(8).keys()].map((i) => (
               <>
                <div onClick={ () => toggleStartLineVertex(i) }>
                  { JSON.stringify(raceConfig.startLine.cuboid[i]).replaceAll('"', '') }
                </div>
                { showStartLineVertex[i] && <>Vertex {i + 1} X:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id={`sl-cuboid-vx-${i}`}
                    name={`sl-cuboid-vx-${i}`}
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, startLine: {
                      ...raceConfig.startLine,
                      cuboid: setCuboidPointXYZ(raceConfig.startLine.cuboid, i, 'x', parseFloat(value.target.value))
                    }})}
                    type="number"
                    validation={register({ required: 'Start Line Cuboid is required' })}
                    value={raceConfig.startLine.cuboid[i][0]}
                />
               Vertex {i + 1} Y:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id={`sl-cuboid-vy-${i}`}
                    name={`sl-cuboid-vy-${i}`}
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, startLine: {
                      ...raceConfig.startLine,
                      cuboid: setCuboidPointXYZ(raceConfig.startLine.cuboid, i, 'y', parseFloat(value.target.value))
                    }})}
                    type="number"
                    validation={register({ required: 'Start Line Cuboid is required' })}
                    value={raceConfig.startLine.cuboid[i][1]}
                />
                Vertex {i + 1} Z:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id={`sl-cuboid-vy-${i}`}
                    name={`sl-cuboid-vy-${i}`}
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, startLine: {
                      ...raceConfig.startLine,
                      cuboid: setCuboidPointXYZ(raceConfig.startLine.cuboid, i, 'z', parseFloat(value.target.value))
                    }})}
                    type="number"
                    validation={register({ required: 'Start Line Cuboid is required' })}
                    value={raceConfig.startLine.cuboid[i][2]}
                />
                </>
                }
               </>
               ))}
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Cuboid (Prism) that defines the startline"
                    src={InfoIcon}
                />
              </Desc>
           </Row>

           <Row>
              <Label>Lanes</Label>
              <Field>
                {[...Array(raceConfig.maxRacers).keys()].map((i) => (
                  <>
                    <div id={`sllId${i}`} onClick={ () => { toggleSartLanes(i, 'location');  } }>
                      { JSON.stringify((raceConfig.startLine?.lanes?.[i]?? { location: [0,0,0], rotation: [0,0,0] })).replaceAll('"', '') }
                    </div>
                    
                    {showStartLanes[i]?.location && <><div>Location {i + 1}</div>
                      X: <ValidatedFormInput
                          labelText=""
                          className="w-input"
                          errors={errors}
                          id={`sll-cuboid-vx-${i}`}
                          name={`sll.-cuboid-vx-${i}`}
                          onChange={(value: any) =>  setStartLane(i, 'location', 'x', parseFloat(value.target.value))}
                          type="number"
                          validation={register({ required: 'Start Line location is required' })}
                          value={(raceConfig.startLine?.lanes?.[i]?.location?.[0]?? 0)}
                      />
                     Y: <ValidatedFormInput
                        labelText=""
                        className="w-input"
                        errors={errors}
                        id={`sll-cuboid-vy-${i}`}
                        name={`sll.-cuboid-vy-${i}`}
                        onChange={(value: any) =>  setStartLane(i, 'location', 'y', parseFloat(value.target.value))}
                        type="number"
                        validation={register({ required: 'Start Line location is required' })}
                         value={(raceConfig.startLine.lanes?.[i]?.location?.[1]?? 0)}
                    />
                    Z: <ValidatedFormInput
                        labelText=""
                        className="w-input"
                        errors={errors}
                        id={`sll-cuboid-vz-${i}`}
                        name={`sll-cuboid-vz-${i}`}
                        onChange={(value: any) =>  setStartLane(i, 'location', 'z', parseFloat(value.target.value))}
                        type="number"
                        validation={register({ required: 'Start Line location is required' })}
                         value={(raceConfig.startLine.lanes?.[i]?.location?.[2]?? 0)}
                    />

                    </>}

                    {showStartLanes[i]?.location && <><div>Rotation {i + 1}</div>
                      X: <ValidatedFormInput
                          labelText=""
                          className="w-input"
                          errors={errors}
                          id={`sll-cuboid-vx-r-${i}`}
                          name={`sll-cuboid-vx-r-${i}`}
                          onChange={(value: any) =>  setStartLane(i, 'rotation', 'x', parseFloat(value.target.value))}
                          type="number"
                          validation={register({ required: 'Start Line rotation is required' })}
                          value={(raceConfig.startLine?.lanes?.[i]?.rotation?.[0]?? 0)}
                      />
                     Y: <ValidatedFormInput
                          labelText=""
                          className="w-input"
                          errors={errors}
                          id={`sll-cuboid-vy-r-${i}`}
                          name={`sll-cuboid-vy-r-${i}`}
                          onChange={(value: any) =>  setStartLane(i, 'rotation', 'y', parseFloat(value.target.value))}
                          type="number"
                          validation={register({ required: 'Start Line rotation is required' })}
                          value={(raceConfig.startLine?.lanes?.[i]?.rotation?.[1]?? 0)}
                      />
                    Z: <ValidatedFormInput
                          labelText=""
                          className="w-input"
                          errors={errors}
                          id={`sll-cuboid-vz-r-${i}`}
                          name={`sll-cuboid-vz-r-${i}`}
                          onChange={(value: any) =>  setStartLane(i, 'rotation', 'z', parseFloat(value.target.value))}
                          type="number"
                          validation={register({ required: 'Start Line rotation is required' })}
                          value={(raceConfig.startLine?.lanes?.[i]?.rotation?.[2]?? 0)}
                      />

                    </>}
                  </>
                ))}
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Lane locations that defines the statring lanes"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           </>}

           {canManageRacingSubsection(user, space, 'finishLine') && <>
             <Divider />
             <h3 onClick={ () => toggleHeading('finishLine') }>{ headingToggles['finishLine'] && <>▼</> }{ !headingToggles['finishLine'] && <>▶</> } Finish Line</h3>
             <Divider />
           </>}
           { headingToggles['finishLine'] && <>
           <Row>
              <Label>Texture</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="fl-texture"
                    name="fl-texture"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, finishLine: {
                      ...raceConfig.finishLine,
                      texture: value.target.value
                    }})}
                    type="text"
                    validation={register({})}
                    value={raceConfig.finishLine.texture}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Url to the texture of the finish line"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           <Row>
              <Label>Cuboid</Label>
              <Field>
               {[...Array(8).keys()].map((i) => (
               <>
                <div onClick={ () => toggleFinishLineVertex(i) }>
                  { JSON.stringify(raceConfig.finishLine.cuboid[i]).replaceAll('"', '') }
                </div>
                { showFinishLineVertex[i] && <>Vertex {i + 1} X:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id={`fl-cuboid-vx-${i}`}
                    name={`fl-cuboid-vx-${i}`}
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, finishLine: {
                      ...raceConfig.finishLine,
                      cuboid: setCuboidPointXYZ(raceConfig.finishLine.cuboid, i, 'x', parseFloat(value.target.value))
                    }})}
                    type="number"
                    validation={register({ required: 'Finish Line Cuboid is required' })}
                    value={raceConfig.finishLine.cuboid[i][0]}
                />
               Vertex {i + 1} Y:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id={`fl-cuboid-vy-${i}`}
                    name={`fl-cuboid-vy-${i}`}
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, finishLine: {
                      ...raceConfig.finishLine,
                      cuboid: setCuboidPointXYZ(raceConfig.finishLine.cuboid, i, 'y', parseFloat(value.target.value))
                    }})}
                    type="number"
                    validation={register({ required: 'Finish Line Cuboid is required' })}
                    value={raceConfig.finishLine.cuboid[i][1]}
                />
                Vertex {i + 1} Z:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id={`fl-cuboid-vy-${i}`}
                    name={`fl-cuboid-vy-${i}`}
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, finishLine: {
                      ...raceConfig.finishLine,
                      cuboid: setCuboidPointXYZ(raceConfig.finishLine.cuboid, i, 'z', parseFloat(value.target.value))
                    }})}
                    type="number"
                    validation={register({ required: 'Finish Line Cuboid is required' })}
                    value={raceConfig.finishLine.cuboid[i][2]}
                />
                </>
                }
               </>
              ))}
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Finish Line Cuboid (Prism)"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
           </>}

           {canManageRacingSubsection(user, space, 'scorersTableLocation') && <>
             <Divider />
             <h3 onClick={ () => toggleHeading('scorersTableLocation') }>{ headingToggles['scorersTableLocation'] && <>▼</> }{ !headingToggles['scorersTableLocation'] && <>▶</> } Scorers Table Location</h3>
             <Divider />
           </>}
           { headingToggles['scorersTableLocation'] && <>
            <Row>
              <Label>Teleport Point</Label>
              <Field>
                <div onClick={ () => setShowScorersTableTeleportPoint(!showScorersTableTeleportPoint) }>
                  { JSON.stringify(raceConfig.scorersTable.teleportPoint).replaceAll('"', '') }
                </div>
               { showScorersTableTeleportPoint && <><div>Location</div>X:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="st-location-x"
                    name="st-location-x"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, scorersTable: {
                      ...raceConfig.scorersTable,
                      teleportPoint: {
                        ...raceConfig.scorersTable.teleportPoint,
                        location: setPointXYZ(raceConfig.scorersTable.teleportPoint.location, 'x', parseFloat(value.target.value))
                      }
                    }})}
                    type="number"
                    validation={register({ required: 'Scorers Table Location is required' })}
                    value={raceConfig.scorersTable.teleportPoint.location[0]}
                />
               Y:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="st-location-y"
                    name="st-location-y"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, scorersTable: {
                      ...raceConfig.scorersTable,
                      teleportPoint: {
                        ...raceConfig.scorersTable.teleportPoint,
                        location: setPointXYZ(raceConfig.scorersTable.teleportPoint.location, 'y', parseFloat(value.target.value))
                      }
                    }})}
                    type="number"
                    validation={register({ required: 'Scorers Table Location is required' })}
                    value={raceConfig.scorersTable.teleportPoint.location[1]}
                />
                Z:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="st-location-z"
                    name="st-location-z"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, scorersTable: {
                      ...raceConfig.scorersTable,
                      teleportPoint: {
                        ...raceConfig.scorersTable.teleportPoint,
                        location: setPointXYZ(raceConfig.scorersTable.teleportPoint.location, 'z', parseFloat(value.target.value))
                      }
                    }})}
                    type="number"
                    validation={register({ required: 'Scorers Table Location is required' })}
                    value={raceConfig.scorersTable.teleportPoint.location[2]}
                />
                <div>rotation</div>X:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="st-rotation-x"
                    name="st-rotation-x"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, scorersTable: {
                      ...raceConfig.scorersTable,
                      teleportPoint: {
                        ...raceConfig.scorersTable.teleportPoint,
                        rotation: setPointXYZ(raceConfig.scorersTable.teleportPoint.rotation, 'x', parseFloat(value.target.value))
                      }
                    }})}
                    type="number"
                    validation={register({ required: 'Scorers Table Rotation is required' })}
                    value={raceConfig.scorersTable.teleportPoint.rotation[0]}
                />
               Y:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="st-rotation-y"
                    name="st-rotation-y"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, scorersTable: {
                      ...raceConfig.scorersTable,
                      teleportPoint: {
                        ...raceConfig.scorersTable.teleportPoint,
                        rotation: setPointXYZ(raceConfig.scorersTable.teleportPoint.rotation, 'y', parseFloat(value.target.value))
                      }
                    }})}
                    type="number"
                    validation={register({ required: 'Scorers Table Rotation is required' })}
                    value={raceConfig.scorersTable.teleportPoint.rotation[1]}
                />
                Z:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="st-rotation-z"
                    name="st-rotation-z"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, scorersTable: {
                      ...raceConfig.scorersTable,
                      teleportPoint: {
                        ...raceConfig.scorersTable.teleportPoint,
                        rotation: setPointXYZ(raceConfig.scorersTable.teleportPoint.location, 'z', parseFloat(value.target.value))
                      }
                    }})}
                    type="number"
                    validation={register({ required: 'Scorers Table Rotation is required' })}
                    value={raceConfig.scorersTable.teleportPoint.rotation[2]}
                />
              </>}
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Scorers Table Teleport Point"
                    src={InfoIcon}
                />
              </Desc>
            </Row>
            </>}

            {canManageRacingSubsection(user, space, 'scoreboard') && <>
              <Divider />
                <h3 onClick={ () => toggleHeading('scoreboard') }>{ headingToggles['scoreboard'] && <>▼</> }{ !headingToggles['scoreboard'] && <>▶</> } Scoreboard</h3>
              <Divider />
            </>}
            { headingToggles['scoreboard'] && <>
              <h4>Scoreboard Cycle:</h4>
              {[...Array(raceConfig.scoreboard.cycle.length).keys()].map((i) => (
                <>
                  { ( editScoreboardCycleItem.isEditting &&  editScoreboardCycleItem.idx === i) && <hr /> }
                  <Row>
                    <Label>{raceConfig.scoreboard.cycle[i].name}</Label>
                    <Field>
                      <LabeledToggleWrapper>
                        <LabeledToggle
                            leftLabel="Active"
                            rightLabel="Inactive"
                            value={raceConfig.scoreboard.cycle[i].isActive}
                            changeHandler={(value) => toggleCustomScorebaordCycleItemActivation(i) }
                        />
                    </LabeledToggleWrapper>
                  </Field>
                  <Desc>
                    { (i > 0) && <ALink onClick={(event) => { event.preventDefault(); moveRaceCycleItem(i, 'UP') }}>↑</ALink> }
                    { (i === 0) && <ALink> &nbsp; </ALink> }
                    { (i < (raceConfig.scoreboard.cycle.length -1)) && <ALink onClick={(event) => { event.preventDefault(); moveRaceCycleItem(i, 'DOWN') }}>↓</ALink> }
                    { (i === (raceConfig.scoreboard.cycle.length -1)) && <ALink> &nbsp; </ALink> }
                    <ALink onClick={(event) => { event.preventDefault(); setEditScoreboardCycleItem( { ...editScoreboardCycleItem, idx: i, isEditting: ( (editScoreboardCycleItem.idx === i)? !editScoreboardCycleItem.isEditting: true ) } ) }}>✎</ALink>
                    { (raceConfig.scoreboard.cycle[i].type === 'CUSTOM') && <ALink onClick={(event) => { event.preventDefault(); removeCustomScorebaordCycleItem(i) }}>🗑</ALink> }
                  </Desc>
                  </Row>

                   { ( editScoreboardCycleItem.isEditting && editScoreboardCycleItem.idx === i) && <>
                      { (raceConfig?.scoreboard?.cycle[i]?.type === 'CUSTOM') && <>
                        <Row>
                          <Label>Name</Label>
                          <Field>
                           <ValidatedFormInput
                                labelText=""
                                className="w-input"
                                errors={errors}
                                id="sb-name"
                                name="sb-name"
                                onChange={(value: any) => editCustomScorebaordCycleItem(i, { name: value.target.value })}
                                type="text"
                                validation={register({})}
                                value={raceConfig.scoreboard.cycle[i].name}
                            />
                          </Field>
                          <Desc>
                            <ReactTooltip effect="solid" />
                            <InactiveInfo
                                data-tip="Enter the name of the custom scoreboard cycle"
                                src={InfoIcon}
                            />
                          </Desc>
                        </Row>
                        <Row>
                          <Label>Start Date</Label>
                          <DateField>
                            <Datetime onChange={(value: any) => editCustomScorebaordCycleItem(i, { startDateTime: value.valueOf() })} value={ new Date(raceConfig.scoreboard.cycle[i].startDateTime) } />
                          </DateField>
                          <Desc>
                            <ReactTooltip effect="solid" />
                            <InactiveInfo
                                data-tip="Enter the start date time for the custom scoreboard cycle"
                                src={InfoIcon}
                            />
                          </Desc>
                        </Row>
                        <Row>
                          <Label>End Date</Label>
                          <DateField>
                            <Datetime onChange={(value: any) => editCustomScorebaordCycleItem(i, { endDateTime: value.valueOf() })} value={ new Date(raceConfig.scoreboard.cycle[i].endDateTime) } />
                          </DateField>
                          <Desc>
                            <ReactTooltip effect="solid" />
                            <InactiveInfo
                                data-tip="Enter the start date time for the custom scoreboard cycle"
                                src={InfoIcon}
                            />
                          </Desc>
                        </Row>
                       </> }
                      <Row>
                        <Label>Duration</Label>
                        <Field>
                         <ValidatedFormInput
                              labelText=""
                              className="w-input"
                              errors={errors}
                              id={`sbcDuration-${i}`}
                              name={`sbcDurration-${i}`}
                              onChange={(value: any) => editCustomScorebaordCycleItem(i, { duration: parseInt(value.target.value) })}
                              type="number"
                              validation={register({})}
                              value={raceConfig.scoreboard.cycle[i]?.duration?? 10}
                              style={{ width: '78px' }}
                          />
                        </Field>
                        <Desc>
                          <ReactTooltip effect="solid" />
                          <InactiveInfo
                              data-tip="Duration in seconds"
                              src={InfoIcon}
                          />
                        </Desc>
                      </Row>
                      <Row>
                        <Label>Include</Label>
                        <Field>
                            <LabeledToggleWrapper>
                              <LabeledToggle
                                  leftLabel="Space Only"
                                  rightLabel="Entire Station"
                                  value={!raceConfig.scoreboard.cycle[i].isStationIncluded}
                                  changeHandler={(value) => editCustomScorebaordCycleItem(i, { isStationIncluded: !raceConfig.scoreboard.cycle[i].isStationIncluded }) }
                              />
                          </LabeledToggleWrapper>
                        </Field>
                        <Desc>
                          <ReactTooltip effect="solid" />
                          <InactiveInfo
                              data-tip="Include only socres achieved in this space or include entire station"
                              src={InfoIcon}
                          />
                        </Desc>
                      </Row>
                      <Row>
                        <Label> </Label>
                        <Field>
                            <LabeledToggleWrapper>
                              <LabeledToggle
                                  leftLabel="All Races"
                                  rightLabel="Tournaments Only"
                                  value={!raceConfig.scoreboard.cycle[i].isTournamentOnly}
                                  changeHandler={(value) => editCustomScorebaordCycleItem(i, { isTournamentOnly: !raceConfig.scoreboard.cycle[i].isTournamentOnly }) }
                              />
                          </LabeledToggleWrapper>
                        </Field>
                        <Desc>
                          <ReactTooltip effect="solid" />
                          <InactiveInfo
                              data-tip="Include only tournament socres or include all scores"
                              src={InfoIcon}
                          />
                        </Desc>
                      </Row>
                      <Row>
                        <Label>Prizes</Label>
                        <Field>
                            <LabeledToggleWrapper>
                              <LabeledToggle
                                  leftLabel="Active"
                                  rightLabel="Inactive"
                                  value={getPrizeConfigForCycle(i).isActive}
                                  changeHandler={(value) => editCustomScorebaordCycleItem(i, { prizes: { ...getPrizeConfigForCycle(i), isActive: !getPrizeConfigForCycle(i).isActive } }) }
                              />
                          </LabeledToggleWrapper>
                        </Field>
                        <Desc>
                          <ReactTooltip effect="solid" />
                          <InactiveInfo
                              data-tip="Should prizes be awared to players in this interval"
                              src={InfoIcon}
                          />
                        </Desc>
                      </Row>
                      {getPrizeConfigForCycle(i).isActive && <>
                        <h4>Prize Config:</h4>
                        <Row>
                          <Label>Name</Label>
                          <Field>
                           <ValidatedFormInput
                                labelText=""
                                className="w-input"
                                errors={errors}
                                id="sbp-name"
                                name="sbp-name"
                                onChange={(value: any) => editCustomScorebaordCycleItem(i, { prizes: { ...getPrizeConfigForCycle(i), name: value.target.value } })}
                                type="text"
                                validation={register({})}
                                value={getPrizeConfigForCycle(i).name}
                            />
                          </Field>
                          <Desc>
                            <ReactTooltip effect="solid" />
                            <InactiveInfo
                                data-tip="Enter the name of this prize"
                                src={InfoIcon}
                            />
                          </Desc>
                        </Row>

                       <Row>
                        <Label>Payout %</Label>
                        <Field>
                         <ValidatedFormInput
                              labelText=""
                              className="w-input"
                              errors={errors}
                              id={`sbcpPayoutPercentage-${i}`}
                              name={`sbcpPayoutPercentage-${i}`}
                              onChange={(value: any) => editCustomScorebaordCycleItem(i, { prizes: { ...getPrizeConfigForCycle(i), rules: { ...getPrizeConfigForCycle(i).rules, payoutAsPercentageOfProfits: parseFloat(value.target.value) } } })}
                              type="number"
                              validation={register({})}
                              value={getPrizeConfigForCycle(i).rules.payoutAsPercentageOfProfits}
                              style={{ width: '78px' }}
                          />
                        </Field>
                        <Desc>
                          <ReactTooltip effect="solid" />
                          <InactiveInfo
                              data-tip="Payout as % of profits"
                              src={InfoIcon}
                          />
                        </Desc>
                      </Row>

                      <Row>
                        <Label>Number Of Prizes</Label>
                        <Field>
                         <ValidatedFormInput
                              labelText=""
                              className="w-input"
                              errors={errors}
                              id={`sbp-number-of-prizes-${i}`}
                              name={`sbp-number-of-prizes-${i}`}
                              onChange={(value: any) => {
                                const nop = parseInt(value.target.value);
                                if(nop > 0) setPayoutPrizeDistributionRules(i, 0, getPrizeConfigForCycle(i).rules.prizeDistributionRules[0], parseInt(value.target.value));
                              }}
                              type="number"
                              validation={register({})}
                              value={getPrizeConfigForCycle(i).rules.numberOfPrizes}
                              style={{ width: '100px' }}
                          />
                        </Field>
                        <Desc>
                          <ReactTooltip effect="solid" />
                          <InactiveInfo
                              data-tip="The number of prizes to give out"
                              src={InfoIcon}
                          />
                        </Desc>
                       </Row>

                      <Row>
                        <Label>Prize Distribution</Label>
                        <Field>
                          <div onClick={ () => toggleShowPrizePrizeRules(i)}>
                            { JSON.stringify(getPrizeConfigForCycle(i).rules.prizeDistributionRules).replaceAll('"', '') }
                          </div>
                          { getShowPrizePrizeRules(i) && <>
                            {[...Array(getPrizeConfigForCycle(i).rules.numberOfPrizes).keys()].map((plIdx) => (
                              <>
                                <span>Place #{plIdx + 1}: </span>
                                <ValidatedFormInput
                                  labelText=""
                                  className="w-input"
                                  errors={errors}
                                  id={`tourcmd-pdr-${i}-${plIdx}`}
                                  name={`tourcmd-pdr-${i}-${plIdx}`}
                                  onChange={(value: any) => {
                                    setPayoutPrizeDistributionRules(i, plIdx, parseFloat(value.target.value));
                                  }}
                                  type="number"
                                  validation={register({})}
                                  value={getPrizeConfigForCycle(i).rules.prizeDistributionRules[plIdx]}
                                  style={{ width: '100px' }}
                              />
                              </>
                            ))}
                          </>}

                        </Field>
                        <Desc>
                          <ReactTooltip effect="solid" />
                          <InactiveInfo
                              data-tip="Set the % of the prize money to be sent to top scores [1st, 2nd, 3rd, etc..]"
                              src={InfoIcon}
                          />
                        </Desc>
                      </Row>

                      <Row>
                        <Label>Alt Min Payout</Label>
                        <Field>
                         <ValidatedFormInput
                              labelText=""
                              className="w-input"
                              errors={errors}
                              id={`sbcpAltMinPayout-${i}`}
                              name={`sbcpAltMinPayout-${i}`}
                              onChange={(value: any) => editCustomScorebaordCycleItem(i, { prizes: { ...getPrizeConfigForCycle(i), rules: { ...getPrizeConfigForCycle(i).rules, altMinPayout: parseFloat(value.target.value) } } })}
                              type="number"
                              validation={register({})}
                              value={getPrizeConfigForCycle(i).rules.altMinPayout}
                              style={{ width: '200px' }}
                          />
                        </Field>
                        <Desc>
                          <ReactTooltip effect="solid" />
                          <InactiveInfo
                              data-tip="Alternative total minimum payout to divide among finalists"
                              src={InfoIcon}
                          />
                        </Desc>
                      </Row>

                      <Row>
                        <Label>Alt Max Payout</Label>
                        <Field>
                         <ValidatedFormInput
                              labelText=""
                              className="w-input"
                              errors={errors}
                              id={`sbcpAltMaxPayout-${i}`}
                              name={`sbcpAltMaxPayout-${i}`}
                              onChange={(value: any) => editCustomScorebaordCycleItem(i, { prizes: { ...getPrizeConfigForCycle(i), rules: { ...getPrizeConfigForCycle(i).rules, altMaxPayout: parseFloat(value.target.value) } } })}
                              type="number"
                              validation={register({})}
                              value={getPrizeConfigForCycle(i).rules.altMaxPayout}
                              style={{ width: '200px' }}
                          />
                        </Field>
                        <Desc>
                          <ReactTooltip effect="solid" />
                          <InactiveInfo
                              data-tip="Alternative total maximum payout to divide among finalists"
                              src={InfoIcon}
                          />
                        </Desc>
                      </Row>

                      <Row>
                        <Label>Provider</Label>
                        <Field>
                          <Select
                            onChange={(value: any) => editCustomScorebaordCycleItem(i, { prizes: { ...getPrizeConfigForCycle(i), provider: value } })}
                            value={getPrizeConfigForCycle(i).provider}
                            options={tournamentProviders}
                            spClassName="noTranslate"
                          />
                        </Field>
                        <Desc>
                          <ReactTooltip effect="solid" />
                          <InactiveInfo
                              data-tip="Select the prize payment API provider"
                              src={InfoIcon}
                          />
                        </Desc>
                     </Row>

                      <Row>
                        <Label>Currency</Label>
                        <Field>
                          <Select
                            onChange={(value: any) => editCustomScorebaordCycleItem(i, { prizes: { ...getPrizeConfigForCycle(i), currency: value } })}
                            value={getPrizeConfigForCycle(i).currency}
                            options={tournamentCurrencies}
                            spClassName="noTranslate"
                          />
                        </Field>
                        <Desc>
                          <ReactTooltip effect="solid" />
                          <InactiveInfo
                              data-tip="Select the prize payment currency to be used to payout prizes"
                              src={InfoIcon}
                          />
                        </Desc>
                     </Row>

                      </>}
                    <hr />
                  </> }
                </>
               ))}
                <ButtonContainer>
                 <AuthButton id="AddSBCycle" onClick={(event) => { event.preventDefault(); addCustomScorebaordCycleItem(); } } disabled={submitting}>
                      +
                 </AuthButton>
               </ButtonContainer>
            </> }

            {canManageRacingSubsection(user, space, 'podium') && <>
              <Divider />
                <h3 onClick={ () => toggleHeading('podium') }>{ headingToggles['podium'] && <>▼</> }{ !headingToggles['podium'] && <>▶</> } Podium</h3>
              <Divider />
            </>}
            { headingToggles['podium'] && <>
              <Row>
                <Label>Start Delay</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="podiumStartDelay"
                      name="podiumStartDelay"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, podium: { ...raceConfig.podium, startDelay: parseFloat(value.target.value)  }} )}
                      type="number"
                      validation={register({ required: 'Start Delay required' })}
                      value={raceConfig.podium.startDelay}
                      style={{ width: '78px' }}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="The number of seconds to await after racers complete the race before auto advancing to podium step"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>

              <Row>
                <Label>Durration</Label>
                <Field>
                 <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      errors={errors}
                      id="podiumDurration"
                      name="podiumDurration"
                      onChange={(value: any) => setRaceConfig( { ...raceConfig, podium: { ...raceConfig.podium, durration: parseFloat(value.target.value)  }} )}
                      type="number"
                      validation={register({ required: 'Podium Durration required' })}
                      value={raceConfig.podium.durration}
                      style={{ width: '78px' }}
                  />
                </Field>
                <Desc>
                  <ReactTooltip effect="solid" />
                  <InactiveInfo
                      data-tip="Podium durration in seconds"
                      src={InfoIcon}
                  />
                </Desc>
             </Row>

             <Row>
              <Label>Player Animations</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="sg_sound"
                    name="sg_sound"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, podium: {
                      ...raceConfig.podium,
                      playerAnimations: [...(value.target.value.split(',').map((a: string) => a.trim()))]
                    } })}
                    type="textarea"
                    validation={register({})}
                    value={raceConfig.podium.playerAnimations.join(', ')}
                    style={{ height: '150px' }}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="1st spot is the winner, 2nd spot is second place, etc...."
                    src={InfoIcon}
                />
              </Desc>
           </Row>

           <Row>
              <Label>Music</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="pwl-music"
                    name="pwl-music"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, podium: {
                      ...raceConfig.podium,
                      music: value.target.value
                    }})}
                    type="text"
                    validation={register({})}
                    value={raceConfig.podium.music}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Music to player when winners are on the podium"
                    src={InfoIcon}
                />
              </Desc>
           </Row>

             <Row>
              <Label>Winner Locations</Label>
              <Field>
                {[...Array(raceConfig.podium.playerAnimations.length).keys()].map((i) => (
                  <>
                    <div onClick={ () => { toggleWinnerLocations(i);  } }>
                      { JSON.stringify((raceConfig.podium?.winnerLocations?.[i]?? { location: [0,0,0], rotation: [0,0,0] })).replaceAll('"', '') }
                    </div>

                    {showPodiumWinnerLocations?.[i] && <><div>Location {i + 1}</div>
                      X: <ValidatedFormInput
                          labelText=""
                          className="w-input"
                          errors={errors}
                          id={`pwl-wl-vx-${i}`}
                          name={`pwl-wl-vx-${i}`}
                          onChange={(value: any) =>  setPodiumWinnerLocation(i, 'location', 'x', parseFloat(value.target.value))}
                          type="number"
                          validation={register({ required: 'Podium Winner location is required' })}
                          value={(raceConfig.podium?.winnerLocations?.[i]?.location?.[0]?? 0)}
                      />
                     Y:  <ValidatedFormInput
                          labelText=""
                          className="w-input"
                          errors={errors}
                          id={`pwl-wl-vy-${i}`}
                          name={`pwl-wl-vy-${i}`}
                          onChange={(value: any) =>  setPodiumWinnerLocation(i, 'location', 'y', parseFloat(value.target.value))}
                          type="number"
                          validation={register({ required: 'Podium Winner location is required' })}
                          value={(raceConfig.podium?.winnerLocations?.[i]?.location?.[1]?? 0)}
                    />
                    Z: <ValidatedFormInput
                          labelText=""
                          className="w-input"
                          errors={errors}
                          id={`pwl-wl-vz-${i}`}
                          name={`pwl-wl-vz-${i}`}
                          onChange={(value: any) =>  setPodiumWinnerLocation(i, 'location', 'z', parseFloat(value.target.value))}
                          type="number"
                          validation={register({ required: 'Podium Winner location is required' })}
                          value={(raceConfig.podium?.winnerLocations?.[i]?.location?.[2]?? 0)}
                    />

                    </>}

                    {showPodiumWinnerLocations?.[i] && <><div>Rotation {i + 1}</div>
                      X: <ValidatedFormInput
                          labelText=""
                          className="w-input"
                          errors={errors}
                          id={`pwl-wr-vx-r-${i}`}
                          name={`pwl-wr-vx-r-${i}`}
                          onChange={(value: any) =>  setPodiumWinnerLocation(i, 'rotation', 'x', parseFloat(value.target.value))}
                          type="number"
                          validation={register({ required: 'Winner location rotation is required' })}
                          value={(raceConfig.podium?.winnerLocations?.[i]?.rotation?.[0]?? 0)}
                      />
                     Y: <ValidatedFormInput
                          labelText=""
                          className="w-input"
                          errors={errors}
                          id={`pwl-wr-vy-r-${i}`}
                          name={`pwl-wr-vy-r-${i}`}
                          onChange={(value: any) =>  setPodiumWinnerLocation(i, 'rotation', 'y', parseFloat(value.target.value))}
                          type="number"
                          validation={register({ required: 'Winner location rotation is required' })}
                          value={(raceConfig.podium?.winnerLocations?.[i]?.rotation?.[1]?? 0)}
                      />
                    Z: <ValidatedFormInput
                          labelText=""
                          className="w-input"
                          errors={errors}
                          id={`pwl-wr-vz-r-${i}`}
                          name={`pwl-wr-vz-r-${i}`}
                          onChange={(value: any) =>  setPodiumWinnerLocation(i, 'rotation', 'z', parseFloat(value.target.value))}
                          type="number"
                          validation={register({ required: 'Winner location rotation is required' })}
                          value={(raceConfig.podium?.winnerLocations?.[i]?.rotation?.[2]?? 0)}
                      />

                    </>}
                  </>
                ))}
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Lane locations that defines the statring lanes"
                    src={InfoIcon}
                />
              </Desc>
            </Row>
           </>}

           {canManageRacingSubsection(user, space, 'raceComplete') && <>
             <Divider />
             <h3 onClick={ () => toggleHeading('raceComplete') }>{ headingToggles['raceComplete'] && <>▼</> }{ !headingToggles['raceComplete'] && <>▶</> } Race Complete</h3>
             <Divider />
           </>}
           { headingToggles['raceComplete'] && <>
           <Row>
              <Label>Teleport Point</Label>
              <Field>
                <div onClick={ () => setShowRaceCompleteTeleportPoint(!showRaceCompleteTeleportPoint) }>
                  { JSON.stringify(raceConfig.raceCompleteTeleportPoint).replaceAll('"', '') }
                </div>
               { showRaceCompleteTeleportPoint && <><div>Location</div>
               X:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="rct-location-x"
                    name="rct-location-x"
                    onChange={(value: any) => setRaceConfig({
                      ...raceConfig,
                        raceCompleteTeleportPoint: { ...raceConfig.raceCompleteTeleportPoint, location: setPointXYZ(raceConfig.raceCompleteTeleportPoint.location, 'x', parseFloat(value.target.value)) }
                    })}
                    type="number"
                    validation={register({ required: 'Race Complete Teleport Point is required' })}
                    value={raceConfig.raceCompleteTeleportPoint.location?.[0]?? 0}
                />
               Y:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="rct-location-y"
                    name="rct-location-y"
                    onChange={(value: any) => setRaceConfig({
                      ...raceConfig,
                        raceCompleteTeleportPoint: { ...raceConfig.raceCompleteTeleportPoint, location: setPointXYZ(raceConfig.raceCompleteTeleportPoint.location, 'y', parseFloat(value.target.value)) }
                    })}
                    type="number"
                    validation={register({ required: 'Race Complete Teleport Point is required' })}
                    value={raceConfig.raceCompleteTeleportPoint.location?.[1]?? 0}
                />
                Z:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="rct-location-z"
                    name="rct-location-z"
                    onChange={(value: any) => setRaceConfig({
                      ...raceConfig,
                        raceCompleteTeleportPoint: { ...raceConfig.raceCompleteTeleportPoint, location: setPointXYZ(raceConfig.raceCompleteTeleportPoint.location, 'z', parseFloat(value.target.value)) }
                    })}
                    type="number"
                    validation={register({ required: 'Race Complete Teleport Point is required' })}
                    value={raceConfig.raceCompleteTeleportPoint.location?.[2]?? 0}
                />
                <div>Rotation</div>
                X:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="rct-rotation-x"
                    name="rct-rotation-x"
                    onChange={(value: any) => setRaceConfig({
                      ...raceConfig,
                        raceCompleteTeleportPoint: { ...raceConfig.raceCompleteTeleportPoint, rotation: setPointXYZ(raceConfig.raceCompleteTeleportPoint.rotation, 'x', parseFloat(value.target.value)) }
                    })}
                    type="number"
                    validation={register({ required: 'Race Complete Teleport Rotation Point is required' })}
                    value={raceConfig.raceCompleteTeleportPoint.rotation?.[0]?? 0}
                />
               Y:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="rct-rotation-y"
                    name="rct-rotation-y"
                    onChange={(value: any) => setRaceConfig({
                      ...raceConfig,
                        raceCompleteTeleportPoint: { ...raceConfig.raceCompleteTeleportPoint, rotation: setPointXYZ(raceConfig.raceCompleteTeleportPoint.rotation, 'y', parseFloat(value.target.value)) }
                    })}
                    type="number"
                    validation={register({ required: 'Race Complete Teleport Rotation Point is required' })}
                    value={raceConfig.raceCompleteTeleportPoint.rotation?.[1]?? 0}
                />
                Z:<ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="rct-rotation-z"
                    name="rct-rotation-z"
                    onChange={(value: any) => setRaceConfig({
                      ...raceConfig,
                        raceCompleteTeleportPoint: { ...raceConfig.raceCompleteTeleportPoint, rotation: setPointXYZ(raceConfig.raceCompleteTeleportPoint.rotation, 'z', parseFloat(value.target.value)) }
                    })}
                    type="number"
                    validation={register({ required: 'Race Complete Teleport Rotation Point is required' })}
                    value={raceConfig.raceCompleteTeleportPoint.rotation?.[2]?? 0}
                />
              </>}
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Race Complete Teleport Point"
                    src={InfoIcon}
                />
              </Desc>
            </Row>
           <Row>
              <Label>Race Results Webhook</Label>
              <Field>
               <ValidatedFormInput
                    labelText=""
                    className="w-input"
                    errors={errors}
                    id="pwl-raceResultsWebhook"
                    name="pwl-raceResultsWebhook"
                    onChange={(value: any) => setRaceConfig( { ...raceConfig, raceResultsWebhook: value.target.value } )}
                    type="text"
                    validation={register({})}
                    value={raceConfig.raceResultsWebhook}
                />
              </Field>
              <Desc>
                <ReactTooltip effect="solid" />
                <InactiveInfo
                    data-tip="Webhook to send race results after race complete"
                    src={InfoIcon}
                />
              </Desc>
           </Row>
          </>}</>}

          <Divider />
            <ButtonContainer>
              <WhiteLinkText onClick={() => onSubmitSuccess()}>Cancel</WhiteLinkText>
              <h1>🏃🏿🏃 🏃‍♂️</h1>
              <AuthButton id="saveButton" disabled={submitting}>
                Save
              </AuthButton>
            </ButtonContainer>
           <Divider />
          </FCon>
        </Form>
      }
    </>
  );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 720px;
    margin: 0 auto;
`;

const ALink = styled.a`
  margin-right: 20px;
  font-size: 20px;
`

const FCon = styled.div`
  padding-top: 100px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  justify-content: center;
`;


const Label = styled.div`
  flex: 1;
  text-align: right;
  padding-right: 10px;
  padding-top: 10px;
  width: 30%;
  margin-right: 10px;
  color: var(--off-white);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.17px;
  align-items: center;
`;

const Field =  styled.div`
  flex: 2;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
`;

const DateField =  styled.div`
  flex: 2;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  color: black;
`;

const Desc = styled.div`
  flex: 1;
  font-size: 0.85em;
  color: #666;
  width: 10%;
  text-align: left;
  margin-left: 10px;
  align-items: center;
  padding-top: 10px;
`;

const InactiveInfo = styled.img`
    margin-left: 10px;
    margin-top: -4px;
    fill: #ffffff;
`;

const LabeledToggleWrapper = styled.div`
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 0px;
`;

const Headliner =  styled.h1`
  align-items: center;
`;

const Runners = styled.h1`
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
`;
