import * as React from 'react';
import styled from 'styled-components/macro';
import { useState } from 'react';
import { useEffect } from 'react';
import { ApiSpace } from '../../api/ApiTypes';
import { getApi, putApi } from '../../api/api';
import Divider from '../Divider';
import { AuthButton } from '../inputs/Button';
import { Modal } from '../layouts/Modal';
import Message from '../layouts/Message';
import ValidatedFormInput from '../inputs/ValidatedFormInput';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '../../images/atom-i-info-alt.svg';
import { WhiteLinkText } from '../Typography';
import { useHistory } from 'react-router-dom';
import { distributePrizes } from '../../utils/PrizeDistHelper';


export const PrizeManager = ({ space }: { space: ApiSpace } )=> {
  const raceConfigKey = 'raceConfig';

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [raceConfig, setRaceConfig] = useState<{ [key: string]: any }>({});
  const [activePrizes, setActivePrizes] = useState<{ [key: string]: any }[]>([]);
  const [prizeBoxStatusState, setPrizeBoxStatusState] = useState<boolean[]>([]);
  const [userBadges, setUserBadges] = useState<{ [key: string]: any }>({});
  const [nwrUpxBalance, setNwrUpxBalance] = useState<string | number>('Loading...');
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [altPoolValues, setAltPoolValues] = useState<number[]>([]);

  const history = useHistory();

  const getActivePrizes = async () => {
    const result = await getApi(`/game/prizes/${space!.id!}`).then(async (resp) => {
      const isBadRequest = (resp.status > 399);
      const res =  await resp.json();
      if(isBadRequest) throw new Error(res.msg);
      const goodRes: { [key: string]: any }[] = res;
      return goodRes.sort((a, b) => {
        const atd = new Date(a.intervalEndDate._seconds * 1000).getTime();
        const btd = new Date(b.intervalEndDate._seconds * 1000).getTime();
        if(atd < btd) return -1;
        if(btd < atd) return 1;
        return 0;
      });
    })
    .catch((e: Error) => {
      console.log('Could not get race config with error: ', e);
      return [];
    });

    const badges = await Promise.all(result.map(async (ap: any) => {
      return await Promise.all(ap.payToUsers.map(async (ptu: any) => await getUserBadge(ptu.userId)));
    }));

    const updatedBadges = badges.flatMap((i) => i as { [key: string]: any }).reduce((acc, ub) => {
      acc[ub.userId as string] = ub;
      return acc;
    }, { ...userBadges });

    setUserBadges({ ...userBadges, ...updatedBadges });

    setActivePrizes(activePrizes.filter(() => false));

    console.log({ activePrizes: [ ...result ] });
    setActivePrizes([...result]);

    return [...result];
  }

  const getNwrUpxBalance = async () => {
    const result = await getApi(`/payment/balance/UPX`).then(async (resp) => {
      const isBadRequest = (resp.status > 399);
      const res = await resp.json();
      if(isBadRequest) throw new Error(res.msg);
      return res;
    })
    .catch((e: Error) => {
      console.log('Could not get race config with error: ', e);
      return { balance: 0 };
    });

    console.log('Balance: ', result);
    setNwrUpxBalance(`${result.balance} UPX`)
  }

  const closePrize = async (i: number) => {
    setIsLoading(true);
    const rpId = activePrizes[i].id;
    await putApi(`/game/prizes/${rpId}/close`);
    await getActivePrizes();
    setShowCloseModal(false);
    setIsLoading(false);
  }

  const payPrize = async (i: number) => {
    setIsLoading(true);
    const rpId = activePrizes[i].id;
    const poolValue = altPoolValues[i];
    const data = poolValue? { poolValue }: undefined;
    const res = await putApi(`/game/prizes/${rpId}/pay`, data).then(async (resp) => {
      const isBadRequest = (resp.status > 399);
      const res = await resp.json();
      if(isBadRequest) throw new Error(res.msg);
      return res;
    })
    .catch((e: Error) => {
      console.log('Could not get race config with error: ', e);
      return { success: false, msg: e.message };
    });

    console.log('Prize Payment Result: ', res);

    if(!res.success) alert(res.msg);
    if(res.success) {
      setPrizeBoxStatus(i);
      const aps = await getActivePrizes();
      const result = aps.filter((p) => p.id !== rpId);
      setActivePrizes([...result]);
    }
    setShowPayModal(false);
    setIsLoading(false);
  }

  const getIsPastDue = (i: number) => {
    const curDtm  = new Date().getTime();
    const iDate   = new Date(activePrizes[i].intervalEndDate._seconds * 1000).getTime();
    const isPastDue = curDtm > iDate;
    return isPastDue;
  }



  const getRaceConfig = async () => {
    const result = await getApi(`/space/${space!.id!}/state/${raceConfigKey}`).then(async (resp) => {
      const isBadRequest = (resp.status > 399);
      const res = await resp.json();
      if(isBadRequest) throw new Error(res.msg);
      return res;
    })
    .catch((e: Error) => {
      console.log('Could not get race config with error: ', e);
      return { value: {} };
    });

    console.log({ raceConfig: { ...raceConfig, ...result.value } });
    setRaceConfig({ ...raceConfig, ...result.value });
  };

  const setPrizeBoxStatus = (i: number) => {
    const newStatus = [ ...prizeBoxStatusState ];
    newStatus[i] = !newStatus[i];
    setPrizeBoxStatusState([ ...newStatus ]);
  }

  useEffect(() => {
    console.log({ space });

    getNwrUpxBalance();

    getRaceConfig().then(() => {
      console.log('Got the race config...');
    });

    getActivePrizes().then(() => {
      console.log('Got the active prizes...');
      setIsLoading(false);
    });
  }, []);

  const getIntervalDates = (i: number) => {
    const from = new Date(activePrizes[i].intervalStartDate._seconds * 1000);
    const to   = new Date(activePrizes[i].intervalEndDate._seconds * 1000);

    return { from, to };
  }

  const getUserBadge = async (userId: string) => {
     if(userBadges[userId]) return userBadges[userId];

     const result = await getApi(`/user/${userId}/badge`).then(async (resp) => {
      const isBadRequest = (resp.status > 399);
      const res = await resp.json();
      if(isBadRequest) throw new Error(res.msg);
      return res;
    })
    .catch((e: Error) => {
      console.log('Could not get userNameBadge with error: ', e);
      return {};
    });

    console.log({ newBadge: result.userBadge });

    return result.userBadge;
  }

  const updateAction = async () => {
    setIsLoading(true);

    const result = await getApi(`/game/prizes/trigger/update`).then(async (resp) => {
      const isBadRequest = (resp.status > 399);
      const res = await resp.json();
      if(isBadRequest) throw new Error(res.msg);
      return res;
    })
    .catch((e: Error) => {
      console.log('Could not update with: ', e);
      return {};
    });

    console.log('Updating Prize Data Result: ', result);

    await getActivePrizes().catch((e: Error) => {
      console.log('Getting new prize data failed with error: ', e);
    });

    setIsLoading(false);
  }

  const overwritePoolValue = (i: number, value: number) => {
    if(value < 0) {
      delete altPoolValues[i];
    } else {
      altPoolValues[i] = value;
    }

    setAltPoolValues([...altPoolValues]);
  };

  const placeValues = (i: number) => {
    try {
      const ap = activePrizes[i];
      const poolValue = altPoolValues[i]?? ap.poolValue;
      const cycle = raceConfig.scoreboard.cycle.find((c: { [key: string]: any }) => c.id === ap.prizeId);
      const payDist = cycle!.prizes.rules.prizeDistributionRules;
      console.log({ poolValue, payDist, cycle, payToUsers: ap.payToUsers });

      const winners = ap.payToUsers.map((ptu: { [key: string]: any }) => {
        return { userId: ptu.userId, time: ptu.time };
      });

      return distributePrizes(winners, poolValue, payDist);
    } catch(e) {
      console.log('Could not get new place values with error: ', e);
      return activePrizes[i].payToUsers;
    }
  };

  return (
    <>
      {!isLoading && <ActivePrizeContainer className="noTranslate">
        <Headliner><LeftCol>Prize Payouts</LeftCol><RightCol></RightCol></Headliner>
        <Divider />
        <PrizeHeader>
          <LeftCol>
            <h3>
              <b>Wallet Balance:</b> {nwrUpxBalance}
            </h3>
            <WhiteLinkText onClick={() => history.push(`/space/${space.id}`)}>Cancel</WhiteLinkText>
          </LeftCol>
          <RightCol>
            <AuthButton id="RemoveMode" onClick={(event) => { event.preventDefault(); updateAction(); }} disabled={false} style={ { width: '100px', marginBottom: '10px' } }>
              Update
            </AuthButton>
          </RightCol>
        </PrizeHeader>
        {activePrizes.map((ap: { [key: string]: any }, i: number) => (
          <>
            <PrizeBox key={`${i}-prizeBox`} onClick={() => setPrizeBoxStatus(i)} className={ getIsPastDue(i)? 'PastDue': 'Open' }>
              <LeftCol>
                <h3>{ap.name}</h3>
                <div><b>Interval Type:</b> {ap.interval}</div>
                <div><b>From:</b> {getIntervalDates(i).from.toString()}</div>
                <div><b>To:</b> {getIntervalDates(i).to.toString()}</div>
              </LeftCol>
              <RightCol>
                <div><b>{ap.poolValue} {ap.currency}</b></div>
              </RightCol>
            </PrizeBox>
            { prizeBoxStatusState[i] && <>
                <PrizeBoxExtended>
                  <CenterCol><b> - Users To Pay - </b></CenterCol>
                </PrizeBoxExtended>
                {activePrizes[i].payToUsers.map((ptu: { [key: string]: any }, i: number) => (
                  <>
                    <PrizeBoxExtended key={`${i}-payToUsers`}>
                      <LeftCol><b>Place: </b> {ptu.place} </LeftCol>
                      <LeftCol><b>Display Name: </b> {userBadges[ptu.userId]?.displayName?? ''} </LeftCol>
                      <LeftCol><b>User ID: </b> {ptu.userId} </LeftCol>
                      <LeftCol><Avatar src={userBadges[ptu.userId]?.avatarUrl?? ''} /></LeftCol>
                      <RightCol><b>{ptu.amount} {ap.currency}</b></RightCol>
                    </PrizeBoxExtended>
                  </>
                ))}
                {(ap.poolValue > 0) && <PrizeBoxExtended key={`${i}-prizeBox-extended-1`}>
                  <PayButton onClick={() => setShowPayModal(true)}>PAY</PayButton>
                </PrizeBoxExtended>}
                <PrizeBoxExtended key={`${i}-prizeBod-extended-2`}>
                  <ActionButton onClick={() => setShowCloseModal(true)}>Close</ActionButton>
                </PrizeBoxExtended>
                <Modal handleClose={() => setShowCloseModal(false)} isOpen={showCloseModal}>
                  <Message
                    title={`Are you sure you want close the "${ap.name}" prize?`}
                    body={`You can not undo this action`}
                    onCancel={() => setShowCloseModal(false)}
                    onConfirm={() => closePrize(i)}
                    confirmButtonText={'Close Prize'}
                  />
                </Modal>
                <Modal handleClose={() => { setShowPayModal(false); overwritePoolValue(i, -1); } } isOpen={showPayModal}>
                  <label htmlFor="authProvider">
                    Pay Amount
                    <ReactTooltip effect="solid" />
                    <InactiveInfo data-tip="You can override the amount you whish to pay" src={InfoIcon} />
                  </label>
                  <ValidatedFormInput
                      labelText=""
                      className="w-input"
                      maxLength={255}
                      errors={ {} as any }
                      id="PaymentField"
                      name="PaymentField"
                      onChange={(e: any) => { overwritePoolValue(i, e.target.value) } }
                      type="number"
                      validation={() => { console.log('validation') } }
                      value={altPoolValues[i]?? ap.poolValue}
                      style={{ width: '200px' }}
                  />
                  <>
                  {placeValues(i).map((ptuu: { [key: string]: any }) => (
                    <div key={`${ptuu.id}-prize-place`}><b>Place:</b> {ptuu.place} <b>Amount:</b> {ptuu.amount}</div>
                  ))}
                  <br/>
                  </>
                  <AuthButton id="Pay" onClick={(event) => { event.preventDefault(); payPrize(i) }} disabled={false} style={ { width: '100px', marginBottom: '10px' } }>
                    Pay!
                  </AuthButton>
                </Modal>
            </>}
          </>
        ))}
        <PrizeFooter>
          <LeftCol>
            <h3>
              <b>Wallet Balance:</b> {nwrUpxBalance} UPX
            </h3>
            <WhiteLinkText onClick={() => history.push(`/space/${space.id}`)}>Cancel</WhiteLinkText>
          </LeftCol>
          <RightCol>
            <AuthButton id="RemoveMode" onClick={(event) => { event.preventDefault(); updateAction(); }} disabled={false} style={ { width: '100px', marginBottom: '10px' } }>
              Update
            </AuthButton>
          </RightCol>
        </PrizeFooter>
        <Divider />
        <Footliner>
          <LeftCol></LeftCol>
          <RightCol></RightCol>
        </Footliner>
      </ActivePrizeContainer>}
    </>

  );

};

const Headliner =  styled.h1`
  display: flex;
  justify-content: space-between; /* Aligns items to the left and right */
`;

const Footliner =  styled.h1`
  display: flex;
  justify-content: space-between; /* Aligns items to the left and right */
`;

const ActivePrizeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 720px;
    margin: 0 auto;
    margin-top: 100px;
`;

const PrizeBox = styled.div`
  display: flex;
  justify-content: space-between; /* Aligns items to the left and right */
  border: 2px solid #ABDC2A;
  margin-bottom: 5px;
  padding: 5px;

  &.Open {
    border: 2px solid #ABDC2A;
  }
  &.PastDue {
    border: 2px solid red;
  }
`;

const LeftCol = styled.div`
  align-self: flex-start;
`;

const RightCol = styled.div`
  align-self: flex-end;
`;

const PrizeBoxExtended = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 700px;
  margin-top: -5px;
  margin-bottom: 5px;
  padding: 5px;
  border-left: 2px solid #ABDC2A;
  border-right: 2px solid #ABDC2A;
  border-bottom: 2px solid #ABDC2A;
`

const Avatar = styled.img`
  width: 100px;
  height: 100px;
`

const CenterCol = styled.div`
  display: flex;
  width: 700px;
  justify-content: center;
`;

const PrizeHeader = styled.div`
  display: flex;
  justify-content: space-between; /* Aligns items to the left and right */
  margin-bottom: 5px;
`;

const PrizeFooter = styled.div`
  display: flex;
  justify-content: space-between; /* Aligns items to the left and right */
  margin-top: 10px;
`;

const ActionButton = styled.button`
  background-color: #2F1CD6;
  color: #FFFFFF;
  font-weight: bold;
  &:hover {
    background-color: red;
  }
`;

const PayButton = styled.button`
  background-color: #FFFFFF;
  color: #01001D;
  font-weight: bold;
  &:hover {
    background-color: #ABDC2A;
    color: #01001D;
  }
`;

const InactiveInfo = styled.img`
    margin-left: 10px;
    margin-top: -4px;
    fill: #ffffff;
`;
