import React, { createContext, useState, useEffect, useContext } from 'react';
import { ApiStream, ApiSpace, ApiStationDetails, ApiPermission, StationPermission } from '../../api/ApiTypes';
import { useParams } from 'react-router-dom';
import { getApi, postApi, putApi, deleteApi } from '../../api/api';
import querystring from 'query-string';
import { StationDetailContext } from '../../context/stationContext';
import useInterval from '../../hooks/useInterval';
import equal from 'fast-deep-equal';
import StreamDestination from './StreamDestination';

export type StreamSourceType = 'HOSTED_URL' | 'WEBCAM' | 'SCREENSHARE' | 'FILE_UPLOAD' | 'STREAM_KEY' | null;
export type StreamStatusType = 'OFFLINE' | 'IDLE' | 'ACTIVE' | 'DISABLED' | null;

export type StreamContextType = {
    loadingStation: boolean;
    stationError: string | null;
    stationDetails: ApiStationDetails | null;
    liveStreamPermission?: StationPermission;
    source: StreamSourceType;
    stream: ApiStream;
    createStream: (source: StreamSourceType) => void;
    refreshStream: () => void;
    forceRefreshStream: () => void;
    updateStreamSpaces: (id: string, spaces: string[], destination: string) => void;
    updateActive: () => void;
    deleteStream: (id: string) => void;
}

/* This seems to depend on StationDetailsWithStats completing */
export const StreamContext = createContext({} as StreamContextType);

export const defaultStream = {
    id: "",
    isActive: false,
    spaces: [],
    destination: "Screen",
    details: {
        streamingEndPoints: {
            http: "",
            https: ""
        },
        streamKey: "",
        createdAt: {
            seconds: null
        },
        playback: {
            id: "",
            policy: "",
            url: ""
        },
        id: "",
        status: "offline",
    },
    isDeleted: false,
    createdBy: "",
} as ApiStream;

const StreamProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [stationError, stationErrorSetter] = useState<string | null>(null)
    const [loadingStation, loadingStationSetter] = useState(false);
    const [stationDetails, stationDetailsSetter] = useState<ApiStationDetails | null>(null);
    let { stationId } = useParams() as any;
    const { spaceId } = useParams() as any;
    let spaceIds;
    // In the case that we are using StreamContext on a /:spaceId route
    if (!stationId) {
        spaceIds = [spaceId]

        const qs = querystring.parse(location.search);
        stationId = qs.stationId;
    } else {
        const stationContext = useContext(StationDetailContext);
        spaceIds = stationContext.stationDetails.spaces.map((space: ApiSpace) => { return space.id; });
    }

    const emptyStream = defaultStream;
    emptyStream.spaces = spaceIds;

    const [ init, setInit ] = useState<boolean>(false);
    const [ source, setSource ] = useState<StreamSourceType>(null);
    const [ stream, setStream ] = useState<ApiStream>(emptyStream);

    const permissions = stationDetails?.station.permissions;
    const liveStreamPermission = permissions?.find((permissionObj) => {
        console.log('[StreamContext] permissions iteration: ', permissionObj);
        return permissionObj.name === 'canLiveStream';
    });

    useEffect(() => {
        if (stationId) {
            getStationDetailsFromStationId().then((stationDetails) => {
                stationDetailsSetter(stationDetails);
            });
        } else if (spaceId) {
            getStationDetailsFromSpaceId().then((stationDetails) => {
                stationDetailsSetter(stationDetails);
            });
        }
    }, [spaceId, stationId]);

    useEffect(() => {
        // Initialize stream data via api
        if(!init){
            refreshStream();
            setInit(true);
        }
    }, [init]);

    useInterval(() => {
        if( ["idle","active"].includes(stream.details.status) ){
            refreshStream();
        }
    }, 5000);

    const refreshStream = () => {
        getApi(`/station/${stationId}/liveStreams`)
        .then(async (streamDetailsResponse) => {
            if (streamDetailsResponse.ok) {
                const existingStreams = await streamDetailsResponse.json();
                    console.log(`Refreshing stream, existingStreams:`, existingStreams);
                    if (existingStreams.length){
                        const primaryStream = existingStreams[0];
                        delete primaryStream.lastUpdated;

                        if (!init || !equal(primaryStream, stream)){
                            console.log(`Found a stream update`);
                            setStream(primaryStream);

                            if (source != "STREAM_KEY"){
                                setSource("STREAM_KEY");
                            }
                            setInit(true);
                        }
                    } else {
                        setStream(emptyStream);
                        setSource(null);
                    }
                }
            })
        .catch((error) => {
            console.log(error);
        });
    };

    const forceRefreshStream = () => {
        window.location.reload();
    };

    const createStream = (source: StreamSourceType) => {
        switch(source){
            case 'STREAM_KEY':
                postApi(`/station/${stationId}/liveStream`, {spaces: stream.spaces, destination: stream.destination})
                .then(async (streamDetailsResponse) => {
                    if (streamDetailsResponse.ok) {
                        const existingStreams = await streamDetailsResponse.json();
                        setSource("STREAM_KEY");
                        refreshStream();
                    }
                })
                .catch((error) => console.log(error));
                break;
        }
    };

    const updateStreamSpaces = (id: string, spaces: string[], destination: string) => {
        //Note: For some reason spaces has wrong type -
        //When spaces is empty it is a boolean -
        //When there is only one space selected it is a string -
        console.log('updating spaces');
        const spacesList: string[] = ((typeof spaces === 'string')? [spaces]: (spaces? spaces: []));
        if(stream.id.length > 0){
            putApi(`/station/${stationId}/liveStream/${id}`, { spaces: spacesList, destination: destination })
            .then(async (streamUpdateResponse) => {
                if(streamUpdateResponse.ok) {
                    stream.destination = destination;
                    refreshStream();
                }
            });

        } else {
            stream.spaces = spaces
            stream.destination = destination;
            setStream(stream);
        }
    };

    const updateActive = () => {
        if(stream.id.length > 0){
            let active = true;
            if(stream.isActive){
               active = false;
            }
            putApi(`/station/${stationId}/liveStream/${stream.id}`, { isActive: active })
                .then(async (streamUpdateResponse) => {
                    if(streamUpdateResponse.ok) {
                        refreshStream();
                    }
                });
        } else {
            console.log('Cannot activate unitinialized stream.');
        }
    };

    const deleteStream = (id: string) => {
        deleteApi(`/station/${stationId}/liveStream/${id}`)
        .then(async (streamDeleteResponse) => {
            if (streamDeleteResponse.ok) {
                refreshStream();
            }
        })
        .catch((error) => console.log(error));
    };

    const deleteExistingStreams = () => {
        getApi(`/station/${stationId}/liveStreams`)
        .then(async (streamDetailsResponse) => {
            if (streamDetailsResponse.ok) {
                const existingStreams = await streamDetailsResponse.json();
                existingStreams.map((currentStream: any) => {
                    deleteStream(currentStream.id);
                });
            }
        });
    };

    const getStationDetailsFromSpaceId = async () => {
        loadingStationSetter(true);
        const station: ApiStationDetails = await getApi(`/space/${spaceId}/station`).then(async (stationDetailsResponse) => {
            if (stationDetailsResponse.ok) {
                const res = await stationDetailsResponse.json();
                console.log('[StreamContext] stationDetailsRes from spaceId: ', res);
                loadingStationSetter(false);
                return res;
            } else {
                console.warn('[StreamContext] getStationDetails from spaceId failed', stationDetailsResponse);
                stationErrorSetter(stationDetailsResponse.statusText);
                loadingStationSetter(false);
                return null;
            }

        });

        return station;
    }

    // TODO: This function and getStationDetailsFromSpaceId should be merged
    const getStationDetailsFromStationId = async () => {
        loadingStationSetter(true);
        const station: ApiStationDetails = await getApi(`/station/${stationId}/details`).then(async (stationDetailsResponse) => {
            if (stationDetailsResponse.ok) {
                const res = await stationDetailsResponse.json();
                console.log('[StreamContext] stationDetailsRes from stationId: ', res);
                loadingStationSetter(false);
                return res;
            } else {
                console.warn('[StreamContext] getStationDetails from stationId failed', stationDetailsResponse);
                stationErrorSetter(stationDetailsResponse.statusText);
                loadingStationSetter(false);
                return null;
            }
        });

        return station;
    }

    return(
        <StreamContext.Provider value={{ stationDetails, loadingStation, stationError, liveStreamPermission, source, stream, createStream, refreshStream, forceRefreshStream, updateStreamSpaces, updateActive, deleteStream }}>
            {children}
        </StreamContext.Provider>
    );
}

export default StreamProvider;
